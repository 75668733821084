// Function to retrieve token from localStorage
export function retrieveToken() {
    const tokenDataString = localStorage.getItem('token');
    
    if (tokenDataString) {
      const tokenData = JSON.parse(tokenDataString);
      const currentTime = new Date().getTime();
      
      if (currentTime <= tokenData.expiresAt) {
        // Token is still valid
        return tokenData.token;
      } else {
        // Token has expired, remove it from localStorage
        localStorage.removeItem('token');
        console.log('Token has expired.');
        return null;
      }
    } else {
      // No token found in localStorage
      console.log('No token found in localStorage.');
      return null;
    }
  }
  