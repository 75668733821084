<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
    <circle :cx="17.5" :cy="17.5" :r="17.5" :fill="fillColor"/>
    <text x="50%" y="50%" text-anchor="middle" fill="white" dy=".3em" font-size="15">{{ label }}</text>
  </svg>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  props: {
    label: String,
    stepNumber: Number  // Expect a step number to be passed
  },
  setup(props) {
    const store = useStore();
    const isActive = computed(() => store.state.currentStep >= props.stepNumber);

    const fillColor = computed(() => {
      const active = isActive.value;
      console.log(`Step ${props.stepNumber} is ${active ? 'active' : 'inactive'}.`);
      return active ? '#ee2b4d' : '#858EA1';
    });

    return {
      fillColor
    };
  }
}
</script>
