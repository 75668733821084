import { createStore } from 'vuex';

export default createStore({
  state: {
    stepsWidth: '75%',
    currentStep: 0,
    currentModal: null,
    currentComponent: 'BaseContent',
    recommendedModels: [],
    showModal: false,
    showModalDomain: false,
    showModalVerifyOwnership: false, // New state property for showModalVerifyOwnership

    fileInfo: {
      file_id: '',
      message: '',
      s3_key: '',
      user_id: '',
      name: '',
      type: '',
      size: 0,
      path: '',
      showModalColumn: false,
    },
    file_uri: '',
    file_id:'',
    activeMenu: '',
    filepolished: false,
    api_key: '',
    trainingStarted: false,
    fineTunedModelId: null,
    rawFileInfo: {},
    formData: {},
    domainName: ''
  },
  mutations: {
    setDomainName(state, domainName) {
      state.domainName = domainName;
    },
    updateStepsWidth(state, newWidth) {
      state.stepsWidth = newWidth;
    },
    setRawfiledata(state, data) {
      state.rawfiledata = data;
    },
    setStep(state, step) {
      state.currentStep = step;
    },
    setActiveMenu(state, menu) {
      state.activeMenu = menu;
    },
    updateCurrentComponent(state, newComponent) {
      state.currentComponent = newComponent;
      console.log(`Updating current component to: ${newComponent}`);
    },
    updateRecommendedModels(state, models) {
      state.recommendedModels = models.map((model) => {
        console.log('Processing model:', model);
        return {
          ...model,
          checked: false
        };
      });

      console.log('Updated models with checked property:', state.recommendedModels);
    },
    openModal(state, modal) {
      console.log(modal);
      state.showModal = true;
      state.currentModal = modal;
    },
    openModalColumn(state, modal) {
      console.log(modal);
      state.showModalColumn = true;
    },
    closeModal(state) {
      state.showModal = false;
      state.currentModal = null;
    },
    closeModalColumn(state) {
      state.showModalColumn = false;
    },
    setFileInfo(state, fileInfo) {
      state.fileInfo = fileInfo;
    },
    resetFileInfo(state) {
      state.fileInfo = {
        file_id: '',
        message: '',
        s3_key: '',
        user_id: '',
        name: '',
        type: '',
        size: 0,
        path: ''
      };
    },
    setFilePolished(state, value) {
      state.filepolished = value;
    },
    setApiKey(state, value) {
      state.api_key = value;
    },
    setFileUri(state, value) {
      state.file_uri = value;
    },
    setFileId(state,value){
      state.file_id = value;
    },
    setTrainingStarted(state, value) {
      state.trainingStarted = value;
    },
    setFineTunedModelId(state, fineTunedModelId) {
      state.fineTunedModelId = fineTunedModelId;
    },
    setRawFileInfo(state, rawFileInfo) {
      state.rawFileInfo = { ...rawFileInfo };
    },
    updateFormData(state, data) {
      state.formData = { ...state.formData, ...data };
      localStorage.setItem('formData', JSON.stringify(state.formData));
    },
    removeFormData(state) {
      state.formData = {};
      localStorage.removeItem('formData');
    },
    showModalDomain(state) {
      state.showModalDomain = true;
    },
    closeModalDomain(state) {
      state.showModalDomain = false;
    },
    // Mutation to show/hide ModalVerifyOwnership
    showModalVerifyOwnership(state) {
      state.showModalVerifyOwnership = true;
    },
    closeModalVerifyOwnership(state) {
      state.showModalVerifyOwnership = false;
    }
  },
  actions: {
    saveDomainName({ commit }, domainName) {
      commit('setDomainName', domainName);
    },
    changeComponent({ commit }, component) {
      commit('updateCurrentComponent', component);
    },
    setFilePolished({ commit }, value) {
      commit('setFilePolished', value);
    },
    setActiveMenu({ commit }, value) {
      commit('setActiveMenu', value);
    },
    changeStepsWidth({ commit }, width) {
      commit('updateStepsWidth', width);
    },
    setStep({ commit }, step) {
      commit('setStep', step);
    },
    openModal({ commit }, modal) {
      commit('openModal', modal);
    },
    openModalColumn({ commit }, modal) {
      commit('openModalColumn', modal);
    },
    setFileInfo({ commit }, fileInfo) {
      commit('setFileInfo', fileInfo);
    },
    resetFileInfo({ commit }) {
      commit('resetFileInfo');
    },
    setApiKey({ commit }, api_key) {
      commit('setApiKey', api_key);
    },
    setFileUri({ commit }, file_uri) {
      commit('setFileUri', file_uri);
    },
    setFileId({ commit }, file_id) {
      commit('setFileId', file_id);
    },
    closeModal({ commit }) {
      commit('closeModal');
    },
    closeModalColumn({ commit }) {
      commit('closeModalColumn');
    },
    setTrainingStarted({ commit }, value) {
      commit('setTrainingStarted', value);
    },
    setFineTunedModelId({ commit }, fineTunedModelId) {
      commit('setFineTunedModelId', fineTunedModelId);
    },
    setRawFileInfo({ commit }, rawFileInfo) {
      commit('setRawFileInfo', rawFileInfo);
    },
    saveFormData({ commit }, data) {
      commit('updateFormData', data);
    },
    removeFormData({ commit }) {
      commit('removeFormData');
    },
    showModalDomain({ commit }) {
      commit('showModalDomain');
    },
    closeModalDomain({ commit }) {
      commit('closeModalDomain');
    },
    // Action to show/hide ModalVerifyOwnership
    showModalVerifyOwnership({ commit }) {
      commit('showModalVerifyOwnership');
    },
    closeModalVerifyOwnership({ commit }) {
      commit('closeModalVerifyOwnership');
    }
  }
});
