<template>
    <div>
        <custom-modal v-if="showModal">
            <template #header>
                <div class="modal-header-content ml-4 mb-4">
                    <h3 class="header-title no-wrap">Install request has been to our engineers</h3>
                    <button class="close-button" @click="closeModal">
                        <i class="pi pi-times close-icon"></i>
                    </button>
                </div>
            </template>
            <template #body>
                <div class="modal-body-content">
                    <p class="modal-paragraph">
                        Please be kind to wait. It doesn't take more than 8 hours <i class="pi pi-exclamation-circle exclamation-icon"></i>
                    </p>
                </div>
            </template>
            <template #footer>
                <div v-if="loading" class="flex align-items-center justify-start mt-2 ml-4 mb-4 font-bold border-round m-2">
                    <ProgressSpinner class="spinner" />
                </div>
                <div v-else class="flex align-items-center justify-start mt-2 ml-4 mb-4 font-bold border-round m-2 mt-4">
                    <Button v-if="!loading" label="Cancel" @click="previous" class="p-button-outlined p-button-rounded mr-2 btn-cancel" />
                    <Button label="Next" @click="handleSubmit" :icon="loading ? '' : 'pi pi-arrow-right'" :disabled="loading" rounded class="bg-theme-color color-black save-btn" />
                 
                </div>
            </template>
        </custom-modal>
    </div>
</template>
<script>    
import CustomModal from '../commons/modal/ModalComponent.vue'
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name:'ModalRequestInstall',
    data() {
        return {
            loading: false,
        }
    },
    components: {
        CustomModal,
        Button,
        ProgressSpinner
    },
    computed: {
        showModal() {
            return this.$store.state.showModal;
        }
    },
    methods: {
        closeModal() {
            this.$emit('close-modal');
        },
        handleSubmit() {
            this.loading = true
            // request http
        }
    }

}
</script>
<style scoped>
.modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header-title {
    font-size: 24px; 
    font-weight: 700; 
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    vertical-align: baseline;
    margin: 0; 
    width: 333px;
    height: 17px;
    top: 396px;
    left: 645px;
}
.close-button {
    border: 2px solid red;
    background-color: red;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -38px;
    left: 100%;
    right: -12px;
    cursor: pointer;
    padding: 0;
}

@media (max-width: 400px) {
       .close-button {
        border: 2px solid red;
        background-color: red;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -38px;
        left: 100%;
        right: -12px;
        cursor: pointer;
        padding: 0;
        }
    }

.close-icon {
    color: black;
    font-size: 20px;
}


.modal-body-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.modal-paragraph {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    display: flex;
    align-items: center;
}

.exclamation-icon {
    margin-left: 0.5rem;
    font-size: 28px;
    color: red;
}

.spinner {
    width: 30px;
    height: 30px;
    justify-content: start;
    margin-left: 20px;
    --p-progress-spinner-color: green;
}
.no-wrap {
    white-space: nowrap;
}

.btn-cancel {
    background-color: transparent;
    color: #14C871;
    border: 1px solid;
}

</style>