export async function getUser(id) {
  const response = await fetch(`/api/users/` + id, {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error("Error while fetching servers");
  }

  const data = await response.json();
  return data.user;
}

export async function updateUser(id, user) {
  const response = await fetch("/api/users/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    throw new Error("Error while updating users");
  }
  const data = await response.json();
  return data.user;
}

export async function login(username, password) {
  const response = await fetch("/api/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error("Error while logging in: " + errorData.error);
  }

  const data = await response.json();
  return data;
}

export async function signup(userData) {
  const response = await fetch("/api/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error("Error while signing up: " + errorData.error);
  }

  const data = await response.json();
  return data;
}