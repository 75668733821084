<template>
  <div class="py-2 px-4">
    <div class="flex flex-row justify-content-start">
      <div class="main-theme-color text-3xl font-bold">VM Management</div>
    </div>
    <div class="flex flex-row justify-content-between pt-2 pb-4">
      <div
        class="flex align-items-center justify-content-center font-bold border-round m-2"
      >
        <BaseInput
          v-model="filters['global'].value"
          placeholder="Filter active VM"
        >
          <i class="pi pi-sliders-h"></i>
        </BaseInput>
      </div>
      <div
        class="flex align-items-center justify-content-center font-bold border-round m-2"
      >
        <Button
          label="New VM"
          icon="pi pi-plus"
          rounded
          @click=handleClick
          class="bg-theme-color border-theme-color color-black"
        />
      </div>
    </div>
    <DataTable
      v-model:filters="filters"
      :value="servers"
       :paginator="true"
      :rows="10"
      :rowsPerPageOptions="[5, 10, 15]"
      tableStyle="min-width: 50rem"
      :globalFilterFields="[
        'server',
        'GPU_name',
        'GPU_usage',
        'region',
        'status',
        'action',
      ]"
    >
      <template #empty>No servers found.</template>
      <Column field="server" header="Groupe Serveur"></Column>
      <Column field="GPU_name" header="GPU"></Column>
      <Column field="GPU_usage" header="CPU"></Column>
      <Column field="region" header="Region"></Column>
      <Column field="status" header="Status"></Column>
      <Column field="action" header="Action">
        <template #body="slotProps">
          <Button
            @click="openMenu($event, slotProps.data)"
            class="main-theme-color"
            type="button"
            text
            rounded
          >
            <i class="pi pi-ellipsis-h" style="font-size: 1.5rem"></i>
          </Button>
          <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
        </template>
      </Column>
    </DataTable>
		<modal-vm-management :show-modal="showModal" @close-modal="closeModal" />
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import BaseInput from "../components/BaseInput";
//import { getServers } from "../services/server.service";
import { FilterMatchMode } from "primevue/api";
import { mapActions } from "vuex";
import Menu from "primevue/menu";
import ModalVmManagement from '../components/vmmanagement/ModalVmManagement.vue'

export default {
  name: "VmManagementPage",
  data() {
    return {
      servers: [
    {
        "server": "server1",
        "GPU_name": "NVIDIA Tesla V100",
        "GPU_usage": "70%",
        "user_id": "user1",
        "region": "Europe",
        "status": "active",
        "server_group": "server-group-1"
    },
    {
        "server": "server2",
        "GPU_name": "NVIDIA Tesla P100",
        "GPU_usage": "65%",
        "user_id": "user2",
        "region": "North America",
        "status": "inactive",
        "server_group": "server-group-2"
    },
    {
        "server": "server3",
        "GPU_name": "NVIDIA A100",
        "GPU_usage": "80%",
        "user_id": "user3",
        "region": "Asia",
        "status": "active",
        "server_group": "server-group-3"
    },
    {
        "server": "server4",
        "GPU_name": "NVIDIA Tesla T4",
        "GPU_usage": "75%",
        "user_id": "user4",
        "region": "Africa",
        "status": "inactive",
        "server_group": "server-group-4"
    },
    {
        "server": "server5",
        "GPU_name": "NVIDIA Tesla K80",
        "GPU_usage": "60%",
        "user_id": "user5",
        "region": "Europe",
        "status": "active",
        "server_group": "server-group-5"
    },
    {
        "server": "server6",
        "GPU_name": "NVIDIA GeForce RTX 2080",
        "GPU_usage": "85%",
        "user_id": "user6",
        "region": "North America",
        "status": "inactive",
        "server_group": "server-group-6"
    },
    {
        "server": "server7",
        "GPU_name": "NVIDIA Quadro RTX 8000",
        "GPU_usage": "90%",
        "user_id": "user7",
        "region": "Asia",
        "status": "active",
        "server_group": "server-group-7"
    },
    {
        "server": "server8",
        "GPU_name": "NVIDIA Titan RTX",
        "GPU_usage": "55%",
        "user_id": "user8",
        "region": "Africa",
        "status": "inactive",
        "server_group": "server-group-8"
    },
    {
        "server": "server9",
        "GPU_name": "NVIDIA Tesla M60",
        "GPU_usage": "78%",
        "user_id": "user9",
        "region": "Europe",
        "status": "active",
        "server_group": "server-group-9"
    },
    {
        "server": "server10",
        "GPU_name": "NVIDIA A100",
        "GPU_usage": "82%",
        "user_id": "user10",
        "region": "North America",
        "status": "inactive",
        "server_group": "server-group-10"
    }
],
      filters: null,
      selectedModel: null,
      showModal: false,
      items: [
        {
          label: "Edit",
          icon: "pi pi-pencil",
          command: () => this.editVm(this.selectedModel),
        },
        {
          label: "Delete",
          icon: "pi pi-trash",
          command: () => this.deleteVm(this.selectedModel),
        },
      ],
    };
  },
  components: {
    DataTable,
    Column,
    Button,
    BaseInput,
    Menu,
    ModalVmManagement
  },
  methods: {
    ...mapActions(["setActiveMenu"]),
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    handleClick() {
      this.showModal = true;
            },
		closeModal() {
				this.showModal = false;
			},
    openMenu(event, model) {
      this.selectedModel = model;
      this.$refs.menu.toggle(event);
    },
    editVm(model) {
      // Handle the edit action with the selected model
      console.log("Edit Vm", model);
    },
    deleteVm(model) {
      // Handle the delete action with the selected model
      console.log("Delete Vm", model);
    },
  },
  async created() {
    this.setActiveMenu("vm-management");
    this.initFilters();
   // this.servers = await getServers();
  },
};
</script>

<style></style>
