<template>
    <div class="auth-container">
      <div class="logo-and-form-container">
        <div class="logo-container">
          <img src="@/assets/img/logo.png" alt="Logo" class="logo" />
        </div>
        <div class="form-container">
          <h2>Signup</h2>
          <div v-if="errorMessage"  class="alert alert-danger" role="alert">
            {{errorMessage}}
          </div>
          <form @submit.prevent="signup">
            <div class="form-group">
              <label for="signup-username">Username:</label>
              <input v-model="userData.username" type="text" id="signup-username" placeholder="Choose a username" required>
            </div>
            <div class="form-group">
              <label for="signup-email">Email:</label>
              <input v-model="userData.email" type="email" id="signup-email" placeholder="Enter your email" required>
            </div>
            <div class="form-group">
              <label for="signup-password">Password:</label>
              <div class="input-with-icon">
                <input v-model="userData.password" :type="showPassword ? 'text' : 'password'" id="signup-password" placeholder="Create a password" required>
                <svg @click="togglePasswordVisibility" class="password-toggle-icon">...</svg>
              </div>
            </div>
            <div class="form-group">
              <label for="confirm-password">Confirm Password:</label>
              <div class="input-with-icon">
                <input v-model="confirmPassword" :type="showConfirmPassword ? 'text' : 'password'" id="confirm-password" placeholder="Confirm your password" required>
                <svg @click="toggleConfirmPasswordVisibility" class="password-toggle-icon">...</svg>
              </div>
            </div>
            <div class="form-group">
              <button type="submit">
                <span v-if="loading">
                  <LoadingSpinner height="20px" width="20px" color="#fff" borderBottomColor="#FF3D00"/>
                </span>
                <span v-else>
                  Signup
                </span>
                </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
	import LoadingSpinner from './commons/LoadingSpinner.vue';
	import axios from "axios";

  export default {
    name: "SignupComponent",
    data() {
      return {
        showPassword: false,
        showConfirmPassword: false,
        userData: {
          username: "",
          email: "",
          password: "",
          plan_id:null,
        },
        confirmPassword: "",
        errorMessage: '',
        loading: false
      };
    },
    created() {
      console.log("All cookies:", document.cookie);

    // Retrieve plan_id from cookie
    const cookieData = this.getCookie('selectedPlan');
    console.log(cookieData)
    if (cookieData) {
      console.log(cookieData)
      this.userData.plan_id = cookieData;
    } else {
      window.location.href = "https://app.islandaiforge.us/signup";
      
    }
  },
    components: {
            LoadingSpinner
        },
    methods: {
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
      },
      toggleConfirmPasswordVisibility() {
        this.showConfirmPassword = !this.showConfirmPassword;
      },
      getCookie(name) {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name + '='))
    ?.split('=')[1];

  return cookieValue ? decodeURIComponent(cookieValue) : null;
},
    async signup() {
      try {
        this.loading = true;

        // Check if passwords match
        if (this.userData.password !== this.confirmPassword) {
          this.errorMessage = 'Passwords do not match.';
          this.loading = false;
          return;
        }

         const baseUrl = process.env.VUE_APP_BASE_URL; // Accessing environment variable
         const signupUrl = `${baseUrl}/signup`; // Constructing the signup URL
       // const signupUrl = `/api/signup`; // Constructing the signup URL

        // Send signup request
        const response = await axios.post(signupUrl, this.userData, {
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (response.status === 200) {
          // Redirect to login page upon successful signup
          const responseData = response.data;
          console.log('Response Data:', responseData);
          this.$router.push("/login");
        } else {
          // Handle signup error
          const errorData = response.data;
          this.errorMessage = errorData.error || 'Signup error.';
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.errorMessage = 'Email already exists. Please use a different email.';
        } else {
          this.errorMessage = 'An error occurred while signing up.';
        }
        console.error("Signup error:", error);
      } finally {
        this.loading = false;
      }
    }
    }
  };
  </script>
  
  <style scoped>
  .auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(180deg, rgba(0, 8, 35, 0.64) 0%, rgba(1, 65, 34, 0.81) 100%);
  }
  
  .logo-and-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
	.logo-container img {
		max-width: 100px; /* Adjust based on your logo size */
		margin-bottom: 20px;
    border-radius: 50%;
	}
  

	@media (max-width: 600px) {
    .logo-container img {
        max-width: 80px; 
      }
  }

  @media (max-width: 400px) {
    .logo-container img {
        max-width: 60px;
    }
  }

  .form-container {
    border: 1px solid #000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 40px;
    color: #3E4450;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    max-width: 400px;
    border-radius: 25px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-group label {
  display: block;
  margin-bottom: 10px; /* Provides space between label and input */
  color: #3E4450; /* Adjust if you want to change the label's text color */
  font-size: 14px; /* Example to adjust label font size if needed */
}
 
  .form-group {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  
  .input-icon-container input {
  width: 100%; /* Ensures input width is not affected by padding */
  padding: 10px;
  padding-right: 50px; /* Ample space for the icon */
  border-radius: 10px;
  border: 1px solid #858EA1;
  font-family: 'Poppins', sans-serif;
}
.input-icon-container {
  position: relative;
  display: flex;
  align-items: center;
}
  
  input[type="email"], input[type="password"], input[type="text"] {
  width: calc(100% - 20px); /* Adjusts the width considering padding and icons */
  padding: 10px;
  padding-right: 40px; /* Space for the icon */
  border-radius: 10px;
  border: 1px solid #858EA1;
  font-family: 'Poppins', sans-serif;
}
  
  .password-toggle-icon, .email-icon {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    fill: #3E4450; /* Change color as needed */
  }
  
  button {
    width: 80%;
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #14C871;
    color: white;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    transition: background-color 0.2s;
    text-align: center;
    margin-left: 10px;
  }
  
  button:hover {
    background-color: #13a862; /* Slightly darker green */
  }

  .alert-danger {
		border: 1px solid #FF0000;
    background-color: #FFEBEB;
		margin-bottom: 20px;
		width: 100%;
		padding: 10px 10px 10px 34px;
		border-radius: 10px;
		font-family: Poppins, sans-serif;
		text-align: center;
	}
  </style>
  
