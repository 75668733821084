<template>
    <div>
        <custom-modal v-if="showModal">
            <template #header>
                <div class="modal-header-content  ml-4 mb-4 ">
                    <h3 class="header-title">NEW VM MANAGEMENT </h3>
                    <button class="close-button" @click="closeModal">
                        <i class="pi pi-times close-icon"></i>
                    </button>
                </div>
            </template>
            <template #body>
                <div class="ml-4">
                    <select v-model="selectedServer" class="rounded-full w-full p-inputtext custom-select">
                        <option disabled value="">Selectionner un serveur</option>
                        <option v-for="server in servers" :key="server.code" :value="server">
                            {{ server.name }}
                        </option>
                    </select>
                    <br>
                    <InputText placeholder="Number of customers" v-model="input1" class="rounded-full w-full p-inputtext" />
                </div>
            </template>
            <template #footer>
                <div v-if="loading" class="flex align-items-center justify-start mt-2 ml-4 mb-4 font-bold border-round m-2">
                    <ProgressSpinner class="spinner" />
				</div>
                <div v-else class="flex align-items-center justify-start mt-2 ml-4 mb-4 font-bold border-round m-2">
                    <Button label="Add" @click="handleSubmit" icon="pi pi-arrow-up-right" rounded class="bg-theme-color color-black save-btn" />
				</div>
            </template>
        </custom-modal>
    </div>
</template>
<script>    
import CustomModal from '../commons/modal/ModalComponent.vue'
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name:'ModalVmManagement',
    props: {
            showModal: Boolean
        },
    data() {
        return {
            input1: '',
            selectedServer:'',
            servers : [
                { name: 'Server 1', code: 'S1' },
                { name: 'Server 2', code: 'S2' },
                { name: 'Server 3', code: 'S3' },
                { name: 'Server 4', code: 'S4' },
                ],
            loading: false
        };
    },
    components: {
        CustomModal,
        Button,
        InputText,
        ProgressSpinner
    },
    methods: {
        closeModal() {
            this.$emit('close-modal');
        },
        handleSubmit() {
            this.loading = true
            const formData = {
                input1: this.input1,
                selectedServer: this.selectedServer
            };
            console.log('Form data:', formData);
            this.$emit('submit', formData);

            setTimeout(() => {
                this.closeModal();
                this.loading = false;
            }, 2000);
        }
    }

}
</script>
<style scoped>
.modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header-title {
    font-size: 24px; 
    font-weight: 700; 
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    vertical-align: baseline;
    margin: 0; 
    width: 333px;
    height: 17px;
    top: 396px;
    left: 645px;
}
.close-button {
    border: 2px solid red;
    background-color: red;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -38px;
    left: 100%;
    right: -12px;
    cursor: pointer;
    padding: 0;
}

@media (max-width: 400px) {
       .close-button {
        border: 2px solid red;
        background-color: red;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -38px;
        left: 100%;
        right: -12px;
        cursor: pointer;
        padding: 0;
        }
    }

.close-icon {
    color: black;
    font-size: 20px;
}
.p-inputtext {
    border-radius: 9999px;
    margin-bottom: 10px;
}
.spinner {
    width: 30px;
    height: 30px;
    justify-content: start;
    margin-left: 20px;
    --p-progress-spinner-color: green;
}
</style>