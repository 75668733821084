<template>
    <div class="py-2 px-4">
      <div class="flex flex-row justify-content-start">
        <div class="main-theme-color text-3xl font-bold">Endpoint</div>
      </div>
  
      <div class="flex flex-row justify-content-between pt-2 pb-4">
        <div
          class="flex align-items-center justify-content-center font-bold border-round m-2"
        >
          <BaseInput
            v-model="filters.global.value"
            placeholder="Search Test/endpoint"
          >
            <i class="pi pi-search"></i>
          </BaseInput>
        </div>
        <!-- <div
          class="flex align-items-center justify-content-center font-bold border-round m-2"
        >
          <Button
            label="Use Model"
            icon="pi pi-plus-circle"
            rounded
            :class="{'bg-theme-color border-theme-color color-black': canUseModel }"
            @click="useModel"
            :disabled="!canUseModel"
          />
        </div> -->
      </div>
      <DataTable
        :modelValue="filters"
        :value="models"
        tableStyle="min-width: 50rem"
        :globalFilterFields="[
          'model_name',
          'base_url',
          'login',
          'signup'
        ]"
        :filters="filters"
        :paginator="true"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 15]"
        v-model:expandedRows="selectedModels"
        :metaKeySelection="metaKey"
        dataKey="id" 
      >
        <template #empty>No model found.</template>
        <!-- <Column expander style="width: 5rem" /> -->
        <Column field="model_name" header="Model"></Column>
        <Column field="base_url" header="Base Url">
          <template #body="slotProps">
            <a :href="slotProps.data.base_url" target="_blank">{{ slotProps.data.base_url }}</a>
          </template>
        </Column>
        <Column field="login" header="Login">
          <template #body="slotProps">
            <a :href="slotProps.data.login" target="_blank">{{ slotProps.data.login }}</a>
          </template>
        </Column>
        <Column field="signup" header="Signup">
          <template #body="slotProps">
            <a :href="slotProps.data.signup" target="_blank">{{ slotProps.data.signup }}</a>
          </template>
        </Column>
        <Column field="status" header="Status">
        
        </Column>
        <template #expansion="slotProps">
                <div class="p-3">
                    <h5>Resultats</h5>
                    <DataTable :value="slotProps.data">
                        <Column field="id" header="Id"></Column>
                    </DataTable>
                </div>
            </template>
         
            <!-- <Column headerStyle="width:4rem">
                            <template #body>
                                <Button icon="pi pi-search" />
                            </template>
                        </Column> -->
      </DataTable>
    </div>
  
    <!-- All Modals  -->
    <modal-training-install-step-one v-if="showModal && currentModal === 'ModalTrainingInstallStepOne'" @next="openModal('ModalTrainingInstallPreferenceStepOne')" @close-modal="closeModal" />
          <modal-training-install-preference-step-one v-if="showModal && currentModal === 'ModalTrainingInstallPreferenceStepOne'" @previous="openModal('ModalTrainingInstallStepOne')"   @next="openModal('ModalTrainingSelectServerStepOne')" @close-modal="closeModal" />
          <modal-plan v-if="showModal && currentModal === 'ModalPlan'" @previous="openModal('ModalTrainingInstallPreferenceStepOne')" @next="openModal('ModalTrainingSelectServerStepOne')" @close-modal="closeModal" />
          <modal-training-select-server-step-one v-if="showModal && currentModal === 'ModalTrainingSelectServerStepOne'" @previous="openModal('ModalTrainingInstallPreferenceStepOne')" @next="openModal('ModalTrainingInstallEndStepOne')"  @close-modal="closeModal" />
          <modal-plan-price-and-payement v-if="showModal && currentModal === 'ModalPlanPriceAndPayement'" @previous="openModal('ModalTrainingSelectServerStepOne')" @next="openModal('ModalTrainingInstallEndStepOne')"   @close-modal="closeModal" />
          <modal-training-install-end-step-one v-if="showModal && currentModal === 'ModalTrainingInstallEndStepOne'"  @previous="openModal('ModalTrainingSelectServerStepOne')"  @close-modal="closeModal" />
  
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import DataTable from "primevue/datatable";
  import Column from "primevue/column";
  // import Button from "primevue/button";
  import BaseInput from "../components/BaseInput";
  //import { getModels } from "../services/model.service"; // Assuming you have a requestInstall method in your service
  import { FilterMatchMode } from "primevue/api";
  import { useStore } from "vuex";
  
  // Import All Modal Training
  import ModalTrainingInstallStepOne from '../components/training/ModalTrainingInstallStepOne.vue'
    import ModalTrainingSelectServerStepOne  from '../components/training/ModalTrainingSelectServerStepOne .vue'
    import ModalTrainingInstallPreferenceStepOne from '../components/training/ModalTrainingInstallPreferenceStepOne.vue'
    import ModalPlan from '../components/training/ModalPlan.vue'
    import ModalPlanPriceAndPayement from '../components/training/ModalPlanPriceAndPayement.vue'
    import ModalTrainingInstallEndStepOne from '../components/training/ModalTrainingInstallEndStepOne.vue'
  
  const models = ref([
    {
        "status": "inactive",
        "action": 24,
        "model_name": "GPT-3",
        "base_url": 'https://tanamtech.online',
        "login": 'https://tanamtech.online/login',
        "signup": "https://tanamtech.online/signup"
    },
    {
        "status": "inactive",
        "action": 24,
        "model_name": "Tacotron 2",
        "base_url": 'https://tanamtech.online',
        "login": 'https://tanamtech.online/login',
        "signup": "https://tanamtech.online/signup"
    },
    {
        "status": "inactive",
        "action": 24,
        "model_name": "VGG16",
        "base_url": 'https://tanamtech.online',
        "login": 'https://tanamtech.online/login',
        "signup": "https://tanamtech.online/signup"
    }
    ,
    {
        "status": "inactive",
        "action": 24,
        "model_name": "ResNet",
        "base_url": 'https://tanamtech.online',
        "login": 'https://tanamtech.online/login',
        "signup": "https://tanamtech.online/signup"
    }
    ,
    {
        "status": "inactive",
        "action": 24,
        "model_name": "BERT",
        "base_url": 'https://tanamtech.online',
        "login": 'https://tanamtech.online/login',
        "signup": "https://tanamtech.online/signup"
    }
    ,
    {
        "status": "inactive",
        "action": 24,
        "model_name": "LLAMA",
        "base_url": 'https://tanamtech.online',
        "login": 'https://tanamtech.online/login',
        "signup": "https://tanamtech.online/signup"
    }
    ,
    {
        "status": "inactive",
        "action": 24,
        "model_name": "DeepSpeech",
        "base_url": 'https://tanamtech.online',
        "login": 'https://tanamtech.online/login',
        "signup": "https://tanamtech.online/signup"
    }

  ]);
  const filters = ref(null);
  const selectedModels = ref([]);
  const store = useStore();
  const metaKey = ref(true);
  const initFilters = () => {
    filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    };
  };
  
  
  const openModal =(modal) =>{
          return 	this.$store.commit('openModal', modal);
          };
  const closeModal = () => {
      return 		this.$store.commit('closeModal');
          };
  
  
  // const useModel = () => {
  //   this.openModal('ModalTrainingInstallStepOne');
  //       return this.$store.state.showModal;
  // };
  
  const setActiveMenu = () => store.dispatch("setActiveMenu", "endpoint");
  
  (async () => {
    setActiveMenu();
    initFilters();
    try {
      //models.value = await getModels();
    } catch (error) {
      console.error("Error fetching models:", error);
    }
  })();
  </script>
  
  <style>
  .text-brand-color {
    color: green;
  }
  
  .btn-request-install {
    background-color: green;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
  
  }
  
  .btn-request-click {
    background-color: rgb(52, 179, 52);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    animation: pulsate 1s infinite;
  }
  
  @keyframes pulsate {
      0% {
          transform: scale(1);
          box-shadow: 0 0 10px rgba(0, 140, 186, 0.7);
      }
      50% {
          transform: scale(1.1);
          box-shadow: 0 0 20px rgba(0, 140, 186, 0.7);
      }
      100% {
          transform: scale(1);
          box-shadow: 0 0 10px rgba(1, 95, 43, 0.7);
      }
  }
  
  
  
  .ui-datatable .ui-state-highlight{
     background-color: green! ;
  }
  </style>
  