<template>
    <div>
        <custom-modal v-if="showModal">
            <template #header>
                <div class="modal-header-content">
                    <h3 class="header-title">Training Installation Recap</h3>
                    <button class="close-button" @click="closeModal">
                        <i class="pi pi-times close-icon"></i>
                    </button>
                </div>
            </template>
            <template #body>
                <div class="recap-container">
                    <div class="recap-content ml-6">
                        <div class="recap-item gap-6">
                            <div class="recap-title">
                                <i class="pi pi-globe icon"></i>
                                <span class="label">Region:</span>
                            </div>
                            <div class="recap-details">
                                <span class="value">{{ region }}</span>
                            </div>
                        </div>
                        <div class="recap-item gap-6">
                            <div class="recap-title">
                                <i class="pi pi-chart-line icon"></i>
                                <span class="label">Expected Traffic:</span>
                            </div>
                            <div class="recap-details">
                                <span class="value">{{ expectedTraffic }}</span>
                            </div>
                        </div>
                        <div class="recap-item gap-6">
                            <div class="recap-title">
                                <i class="pi pi-database icon"></i>
                                <span class="label">Dataset:</span>
                            </div>
                            <div class="recap-details">
                                <span class="value">{{ dataset }}</span>
                            </div>
                        </div>
                        <div class="recap-item gap-6">
                            <div class="recap-title">
                                <i class="pi pi-server icon"></i>
                                <span class="label">Server Type:</span>
                            </div>
                            <div class="recap-details">
                                <span class="value">{{ serverType }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div v-if="loading" class="footer-loading">
                    <ProgressSpinner class="spinner" />
                </div>
                <div v-else class="footer-buttons mb-4 mt-4">
                    <Button label="Previous" @click="previous" class="p-button-outlined p-button-rounded btn-cancel font-bold" />
                    <Button label="Install" @click="Install" :icon="loading ? '' : 'pi pi-arrow-right'" :disabled="loading" rounded class="bg-theme-color color-black save-btn font-bold" />
                </div>
            </template>
        </custom-modal>
    </div>
</template>

<script>
import CustomModal from '../commons/modal/ModalComponent.vue'
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import axios from 'axios'
import { mapActions } from 'vuex';


export default {
    name: 'ModalTrainingInstallEndStepOne',
    data() {
        return {
            loading: false,
            region: '',  // Example default value, update as necessary
            expectedTraffic: '',  // Example value, update as necessary
            dataset: '',  // Example value, update as necessary
            serverType: '',  // Example value, update as necessary
            userId: '',   // New data property for userId
            planId: '', 
            modelId: '',  // Include selectedModelId in the request payload

        }
    },
    components: {
        CustomModal,
        Button,
        ProgressSpinner,
    },
    computed: {
        showModal() {
            return this.$store.state.showModal;
        },
    },
    created() {
        this.loadFormData();
    },
    methods: {
        ...mapActions(['setActiveMenu']),

        previous() {
            this.$emit('previous');
        },
        closeModal() {
            this.$emit('close-modal');
        },
        async Install() {
            console.log('end step',localStorage.getItem('formData'))
            const verifiedTraining = JSON.parse(localStorage.getItem('formData')).trainingInstallPreference;
            console.log(verifiedTraining, this.serverType)
                              // const savedFormData = JSON.parse(localStorage.getItem('formData'));
            // combinaison du valeur du training et type serveur
            if (verifiedTraining.trainingChecked === true && this.serverType =="shared") {
                try {
                    this.loading = true; // Set loading to true before making the request
                    console.log('region:', typeof this.region, this.region);
                    console.log('expectedTraffic:', typeof this.expectedTraffic, this.expectedTraffic);
                    console.log('dataset:', typeof this.dataset, this.dataset);
                    console.log('serverType:', typeof this.serverType, this.serverType);
                    console.log('model_id:', typeof this.modelId, this.modelId);
                    console.log('user_id:', typeof this.userId, this.userId);
                    const response = await axios.post('https://sh.islandaiforge.us/shared-install', {
                        region: this.region,
                        expectedTraffic: JSON.stringify(this.expectedTraffic),
                        dataset: this.dataset,
                        serverType: this.serverType,
                        model_id:this.modelId,
                        user_id:this.userId,
                    });
                        if (response.status === 200) {
                           // ;
                           const savedFormData = JSON.parse(localStorage.getItem('formData'));
                           console.log('saved form data',savedFormData)
                           let trainingChecked = savedFormData.trainingInstallPreference.trainingChecked;
                            console.log(trainingChecked)
                            this.$emit('close-modal');
                            this.goToStep(2);
                            this.setActiveMenu('fine-tuning');
                            this.$router.push('/fine-tuning');
                            this.$store.commit('updateCurrentComponent', 'UploadFile');
                            this.$store.dispatch('removeFormData');
                            // if(trainingChecked){
                            //     console.log('step 2', verifiedTraining);
                            // this.goToStep(2);
                            // this.$store.dispatch('removeFormData');
                            // }else{
                            //     console.log('step 2', verifiedTraining);
                            // this.goToStep(3);
                            // this.$store.commit('updateCurrentComponent', 'UploadFile');
                            // this.$store.dispatch('removeFormData');

                            // }

                            
                        } else {
                            console.error('Installation failed with status:', response.status);
                            // Handle other statuses as needed
                        }}
                        
                        catch(error) {
                                console.error('Error during installation request:', error);
                            } 
                // https://sh.islandaiforge.us/shared-intall"
            } else {
                console.log('step 3', verifiedTraining);
                this.goToStep(3);
                this.setActiveMenu('test-endpoint');
                this.$router.push('/test-endpoint');
                this.$store.commit('updateCurrentComponent', 'testingphase');
                this.$store.dispatch('removeFormData');
            }
        },
        goToStep(step) {
            this.$store.commit('setStep', step);
        },
        loadFormData() {
           const savedFormDataAudiance = JSON.parse(localStorage.getItem('formData')).selectYourAudiance
            const savedFormDataServer = JSON.parse(localStorage.getItem('formData')).trainingInstallServer
          

            console.log('ttt', localStorage.getItem('formData'))
            if (savedFormDataAudiance) {
                console.log('region',savedFormDataAudiance.region)
                this.region = savedFormDataAudiance.region || '';
                this.expectedTraffic = savedFormDataAudiance.expected_traffic || '';
                this.dataset = savedFormDataAudiance.datasetSize || '';
            }
            if (savedFormDataServer) {
                this.serverType = savedFormDataServer.server || '';
            }
             // Retrieve and parse userId and planId from localStorage
            const storedUserId = localStorage.getItem('userId');
            const storedPlanId = localStorage.getItem('planId');
            const storedModelId = localStorage.getItem('selectedModelId');  // Retrieve the selected model ID


            if (storedUserId) {
                this.userId = JSON.parse(storedUserId).userId
            }

            if (storedPlanId) {
               this.planId = storedPlanId
            }
            if (storedModelId) {
                console.log (storedModelId)
                this.modelId = storedModelId
            }
        },
    }
}
</script>

<style scoped>
.modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    text-align: center;
    width: 100%;
}


  .close-button {
      border: 2px solid red;
      background-color: red;
      border-radius: 50%;
      width: 43px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -38px;
      left: 100%;
      right: -12px;
      cursor: pointer;
      padding: 0;
  }

  @media (max-width: 400px) {
      .close-button {
          border: 2px solid red;
          background-color: red;
          border-radius: 50%;
          width: 43px;
          height: 43px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -38px;
          left: 100%;
          right: -12px;
          cursor: pointer;
          padding: 0;
      }
  }


.close-icon {
    font-size: 20px;
    color: #666;
}

.recap-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.recap-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    max-width: 600px; /* Adjust as necessary */
    margin-top: 20px;
}

.recap-item {
    display: flex;
    margin-bottom: 15px;
    width: 100%;
}

.recap-title {
    display: flex;
    align-items: center;
    flex: 1;
    font-weight: bold;
    font-size: 18px;
    color: #555;
}

.icon {
    font-size: 24px;
    margin-right: 10px;
    color: #888;
}

.recap-details {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    color: #333;
}

.footer-loading,
.footer-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.btn-cancel,
.save-btn {
    margin: 0 10px;
}
</style>
