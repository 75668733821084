<template>
  <span v-if="show" class="spinner"></span>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    show: {
      type: Boolean,
      default: true // Par défaut, il est visible
    },
    height: {
      type: String,
      default: '48px'
    },
    width: {
      type: String,
      default: '48px'
    },
    color: {
      type: String,
      default: '#FFF'
    },
    borderBottomColor: {
      type: String,
      default: '#FF3D00'
    }
  },
  computed: {
    spinnerStyle() {
      return {
        width: this.width,
        height: this.height,
        border: `5px solid ${this.color}`,
        borderBottomColor: this.borderBottomColor,
        borderRadius: '50%',
        display: 'inline-block',
        boxSizing: 'border-box',
        animation: 'rotation 1s linear infinite'
      };
    }
  }
};
</script>

<style scoped>
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  width: 20px;
  height: 20px;
  border: 5px solid #FFF;
  border-bottom-color: #FF3D00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
    }
</style>
