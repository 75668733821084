<template>
    <div>
        <custom-modal v-if="showModal">
            <template #header>
                <div class="modal-header-content ml-4 mb-4">
                    <h3 class="header-title no-wrap">TYPE OF SERVER</h3>
                    <button class="close-button" @click="closeModal">
                        <i class="pi pi-times close-icon"></i>
                    </button>
                </div>
            </template>
            <template #body>
                <div class="ml-4">
                    <div class="mb-4">
                        <div class="server-option" @click="handleServerOption('shared')" :class="{ 'selected': serverOption === 'shared' }">
                            <input type="radio" v-model="serverOption" value="shared" class="p-radio" :disabled="user_planId === 'plan_basique'" />
                            <label>Shared server</label>
                        </div>
                        <div class="server-option" :class="{ 'selected': serverOption === 'dedicated' }">
                            <input type="radio" v-model="serverOption" value="dedicated" class="p-radio" :disabled="user_planId === 'eb01c9a7-da20-41ce-bcb5-75d27a3ec2a6'" />
                            <label class="disabled">Dedicated server <span @click="handleDedicatedServerUpgrade" class="font-bold" >(Upgrade)</span></label>
                        </div>
                    </div>
                    <div class="flex align-items-center mb-4 ml-4 mt-2">
                        <Checkbox v-model="autoScale" class="p-checkbox" :disabled="user_planId !== '39180686-a03b-4527-baec-14b21ed0796c'" binary="true" />
                        <label for="ingredient1" class="ml-2"> Auto scale </label>
                    </div>
                </div>
            </template>
            <template #footer>
                <div v-if="loading" class="flex align-items-center justify-start mt-2 ml-4 mb-4 font-bold border-round m-2">
                    <ProgressSpinner class="spinner" />
                </div>
                <div v-else class="flex align-items-center justify-start mb-4 ml-4 mb-4 font-bold border-round m-2">
                    <Button v-if="!loading" label="Previous" @click="previous" class="p-button-outlined p-button-rounded mr-2 btn-cancel font-bold" />
                    <Button label="Next" @click="handleSubmit" :icon="loading ? '' : 'pi pi-arrow-right'" :disabled="loading" rounded class="bg-theme-color color-black save-btn font-bold" />
                </div>
            </template>
           
        </custom-modal>
    </div>
    {{ showUpgradeModalBasic }}
            <upgrade-modal :show-modal="showUpgradeModalBasic" @previous="cancel" @close-modal="showUpgradeModal = false" @upgrade="handleUpgrade" />
</template>

<script>
import CustomModal from '../commons/modal/ModalComponent.vue'
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import Checkbox from 'primevue/checkbox';
import UpgradeModal from './UpgradeModal.vue';
import axios from "axios";

export default {
    name: 'ModalTrainingSelectServerStepOne',
    data() {
        return {
            user_planId: '',
            autoScale: true,
            serverOption: 'shared',
            loading: false,
            showUpgradeModalBasic: false
        };
    },
    components: {
        CustomModal,
        Button,
        ProgressSpinner,
        Checkbox,
        UpgradeModal
    },
    computed: {
        showModal() {
            return this.$store.state.showModal;
        }
    },
    created() {
        this.getUserDetails();
    },
    methods: {
        cancel() {
            this.showUpgradeModalBasic = false;
        },
        next() {
            this.$emit('showPayementCard');
        },
        closeModal() {
            this.$emit('close-modal');
        },
        handleDedicatedServerUpgrade() {
            this.showUpgradeModal();
            // if (this.user_id === 'plan_basique') {
            //     console.log('plan basique')
            //     this.showUpgradeModal();
            // } else {
            //     this.serverOption = 'dedicated';
            // }
        },
        handleServerOption(option) {
            if (this.user_planId !== 'plan_basique' || option !== 'dedicated') {
                this.serverOption = option;
            }
        },
        handleUpgrade(plan) {
            console.log('Upgrade to', plan);
            this.showUpgradeModalBasic = false;
        },
        handleSubmit() {
            const formData = {
                server: this.serverOption,
                autoScale: this.autoScale
            };
            console.log('training',formData)
            this.$store.dispatch('saveFormData', { trainingInstallServer: formData });
            this.$emit('next');
        },
        previous() {
            this.$emit('previous');
        },
        showUpgradeModal() {
            console.log('show upgrade nest working')
            this.showUpgradeModalBasic = true;
        },
        async getUserDetails() {
            try {
                const baseUrl = process.env.VUE_APP_BASE_URL; 

                const userId = JSON.parse(localStorage.getItem('userId')).userId;
                const storedTokenData = localStorage.getItem('token');
                const token = storedTokenData ? JSON.parse(storedTokenData).token : '';
                const url = `${baseUrl}/user/${userId}`;

// Make the Axios GET request
                try {
                    const response = await axios.get(url, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    // Check if the request was successful
                    if (response.status === 200) {
                        console.log('User data:', response.data);
                        this.user_planId = response.data.plan_details.plan_id;
                        localStorage.setItem('planId', JSON.stringify(this.user_planId ));

                    } else {
                        console.error('Failed to fetch user details');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }



                
               
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        }
    }
}
</script>

<style scoped>
.modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    vertical-align: baseline;
    margin: 0;
    width: 333px;
    height: 17px;
    top: 396px;
    left: 645px;
}

.close-button {
    border: 2px solid red;
    background-color: red;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -38px;
    left: 100%;
    right: -12px;
    cursor: pointer;
    padding: 0;
}

@media (max-width: 400px) {
    .close-button {
        border: 2px solid red;
        background-color: red;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -38px;
        left: 100%;
        right: -12px;
        cursor: pointer;
        padding: 0;
    }
}

.close-icon {
    color: black;
    font-size: 20px;
}

.p-inputtext {
    border-radius: 9999px;
    margin-bottom: 10px;
    padding: 13px; 
    margin-bottom: 10px;
}

.spinner {
    width: 30px;
    height: 30px;
    justify-content: start;
    margin-left: 20px;
    --p-progress-spinner-color: green;
}

.error {
    border: 1px solid red;
}

.error-message {
    color: red;
}

.btn-cancel {
    background-color: transparent;
    color: #14C871;
    border: 1px solid;
}

.server-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 9999px;
    border: 1px solid #ccc;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #F0F0F0;
}


</style>
