<template>
	<div class="py-2 px-4">
		<AppMainContent />
	</div>
</template>

<script>
	import AppMainContent from '../components/AppMainContent.vue';
	import { mapActions } from 'vuex';
	export default {
		name: 'DashboardPage',
		data() {
			return {};
		},
		components: {
			AppMainContent
		},
		methods: {
			...mapActions(['setActiveMenu'])
		},
		created() {
			this.setActiveMenu('dashboard');
		}
	};
</script>

<style>
	.main-theme-color {
		color: #14c871;
	}
	.color-black {
		color: black;
	}
	.rounded-left {
		border-top-left-radius: 2rem;
		border-bottom-left-radius: 2rem;
	}
	.rounded-right {
		border-top-right-radius: 2rem;
		border-bottom-right-radius: 2rem;
	}
</style>
