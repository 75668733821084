<template>
  <div class="main-content-container">
    <div class="main-left-column">
      Business info
    </div>
    <div class="main-right-column">
      <div class="use-case-header"></div>
      <div class="use-case-row">
        <div v-for="(model, index) in models" :key="index" class="use-case-column">
          <div class="use-case-clickable" @click="toggleCheckbox(index)">
            <div class="use-case-icon">
              <svg :class="{ 'checked': isChecked(index) }" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <circle cx="9" cy="9" r="8.5" :stroke="isChecked(index) ? '#14C871' : '#858EA1'" :stroke-width="isChecked(index) ? 2 : 1"/>
                <!-- Checkmark path, only visible if checked -->
                <path v-if="isChecked(index)" d="M13 6.6C13 6.75353 12.9442 6.90712 12.8326 7.02412L8.26116 11.8241C8.15 11.9419 8.00357 12 7.85714 12C7.71071 12 7.56464 11.9414 7.45321 11.8242L5.1675 9.42422C5.05589 9.3075 5 9.15375 5 9C5 8.65725 5.26696 8.4 5.57143 8.4C5.71764 8.4 5.86393 8.45859 5.97536 8.57578L7.85714 10.5525L12.025 6.17625C12.1357 6.05869 12.2821 6 12.4286 6C12.7339 6 13 6.25688 13 6.6Z" fill="white"/>
              </svg>
            </div>
            <div class="use-case-content">
              <div class="use-case-title">{{ model.model }}</div>
              <div v-if="model.installed" class="use-case-text">Installed</div>
              <div v-else class="use-case-text">
                <button class="request-install-button" :class="{ 'highlight': model.highlight }" @click="requestInstall(model, index)">
                  {{ model.requesting ? 'Requesting...' : (model.requestSent ? 'Request Sent' : 'Request Install') }}
                </button>
                <div v-if="model.requestSent" class="request-info-text">It could take up to 8 hours for our engineer to install it.</div>
              </div>
            </div>
          </div>
          <modal-request-install v-if="showModal && !model.installed" @close-modal="closeModal"/>

        </div>
      </div>
            <!-- Coupon Input -->
            <div class="coupon-container">
        <input type="text" v-model="couponCode" placeholder="Enter coupon code" class="coupon-input" />
        <button @click="validateCoupon" class="coupon-button">Apply Coupon</button>
        <div v-if="couponMessage" class="coupon-message">{{ couponMessage }}</div>
      </div>

        <div v-if="isChecked" class="install-button-parent">

          <div v-if="installing" class="installing-text">Installing...</div>
          <div v-if="receivedMessage" class="received-message">{{ receivedMessage }}</div>
          <div v-else @click="installApplication">
            <!-- <div class='install'>Install</div>  -->
            <Button label="Install" @click="installApplication" :icon="loading ? '' : 'pi pi-arrow-up-right'" :disabled="loading" rounded class="bg-theme-color color-black save-btn font-bold" />
          <!-- <div class="icon-install"> 

            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 18 17" fill="none">
              <path d="M3.15839 1H16.109V13.8571" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1 16L16.1091 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div> -->
        </div>
        <div class="install-button-2"></div>
        <div class="install-button-3"></div>
      </div>
    </div>
  </div>
  <div>
    <div>
      <div class="use-case-icon"></div>
      <div class="use-case-content">
        <div class="use-case-title"></div>
      </div>
    </div>
  </div>
  <!-- Additional use cases if needed -->
  <modal-training-install-step-one v-if="showModal && currentModal === 'ModalTrainingInstallStepOne'" @next="openModal('ModalTrainingInstallPreferenceStepOne')" @close-modal="closeModal" />
  <modal-training-install-preference-step-one v-if="showModal && currentModal === 'ModalTrainingInstallPreferenceStepOne'" @previous="openModal('ModalTrainingInstallStepOne')" @next="openModal('ModalTrainingSelectServerStepOne')" @close-modal="closeModal" />
  <modal-plan v-if="showModal && currentModal === 'ModalPlan'" @previous="openModal('ModalTrainingInstallPreferenceStepOne')" @next="openModal('ModalTrainingSelectServerStepOne')" @close-modal="closeModal" />
  <modal-training-select-server-step-one v-if="showModal && currentModal === 'ModalTrainingSelectServerStepOne'" @previous="openModal('ModalTrainingInstallPreferenceStepOne')" @next="openModal('ModalTrainingInstallEndStepOne')" @close-modal="closeModal" />
  <modal-plan-price-and-payement v-if="showModal && currentModal === 'ModalPlanPriceAndPayement'" @previous="openModal('ModalTrainingSelectServerStepOne')" @next="openModal('ModalTrainingInstallEndStepOne')" @close-modal="closeModal" />
  <modal-training-install-end-step-one v-if="showModal && currentModal === 'ModalTrainingInstallEndStepOne'" @previous="openModal('ModalTrainingSelectServerStepOne')" @close-modal="closeModal" />
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css';
import ModalTrainingInstallStepOne from '../components/training/ModalTrainingInstallStepOne.vue';
import ModalTrainingSelectServerStepOne from '../components/training/ModalTrainingSelectServerStepOne .vue';
import ModalTrainingInstallPreferenceStepOne from '../components/training/ModalTrainingInstallPreferenceStepOne.vue';
import ModalPlan from '../components/training/ModalPlan.vue';
import ModalPlanPriceAndPayement from '../components/training/ModalPlanPriceAndPayement.vue';
import ModalTrainingInstallEndStepOne from '../components/training/ModalTrainingInstallEndStepOne.vue';
import ModalRequestInstall from '../components/training/ModalRequestInstall.vue';
import axios from 'axios';
import Button from 'primevue/button';

export default {
  name: 'BaseContent',
  data() {
    return {
      models: this.$store.state.recommendedModels.map(model => ({
        ...model,
        checked: false,
        requesting: false,
        requestSent: false,
        highlight: false
      })),
      useCases: {
        chatbot: { title: 'Custom Chatbot', checked: false },
        callcenter: { title: 'Call center quality assurance', checked: false },
        medical: { title: 'Medical health diagnosis', checked: false }
      },
      receivedMessage: '',
      installing: false,
      couponCode: '', // Add coupon code to data
      couponMessage: '', // Message to show coupon status
      discount: 0 // S
    };
  },
  components: {
    ModalTrainingInstallStepOne,
    ModalTrainingInstallPreferenceStepOne,
    ModalTrainingSelectServerStepOne,
    ModalPlan,
    ModalPlanPriceAndPayement,
    ModalTrainingInstallEndStepOne,
    Button,
    ModalRequestInstall
  },
  created() {
    this.ws = new WebSocket('wss://staging.islandaiforge.us/ws');
    this.ws.onopen = () => {
      console.log('Connected to WebSocket server!');
    };
    this.ws.onmessage = (event) => {
      console.log('Received message:', event.data);
      this.receivedMessage = event.data;
    };
    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  },
  computed: {
    isAnyChecked() {
      return this.models.some(model => model.checked);
    },
    showModal() {
      return this.$store.state.showModal;
    },
    currentModal() {
      return this.$store.state.currentModal;
    },
    hasCheckedModels() {
      return this.models.some(model => model.checked);
    }
  },
  methods: {
    openModal(modal) {
      this.$store.commit('openModal', modal);
    },
    closeModal() {
      this.$store.commit('closeModal');
    },
    toggleCheckbox(index) {
      const model = this.models[index];
      if (model.installed) {
        this.models.forEach((m, i) => {
          if (i !== index) m.checked = false;
        });
        model.checked = !model.checked;
          // Store the selected model ID in localStorage if checked
          if (model.checked) {
            console.log('model id selected',typeof model.model_id)
          localStorage.setItem('selectedModelId', model.model_id);
        } else {
          localStorage.removeItem('selectedModelId');
        }
      } else {
        model.highlight = true;
        setTimeout(() => {
          model.highlight = false;
        }, 2000); // Remove highlight after 2 seconds
      }
    },
    async validateCoupon() {
      const baseUrl = process.env.VUE_APP_BASE_URL;
      try {
        const response = await axios.post(`${baseUrl}/validate-coupon`, { coupon: this.couponCode });
        if (response.data.valid) {
          this.couponMessage = `Coupon applied! Discount: ${response.data.discount}%`;
          this.discount = response.data.discount; // Store the discount
        } else {
          this.couponMessage = 'Invalid coupon code. Please try again.';
        }
      } catch (error) {
        console.error('Error validating coupon:', error);
        this.couponMessage = 'Error validating coupon.';
      }
    },
    async requestInstall(model, index) {
      if (model.requesting || model.requestSent) return;
      // open modal request install 
      this.openModal('ModalRequestInstall');

      this.models[index].requesting = true;
      const baseUrl = process.env.VUE_APP_BASE_URL;

      try {
        await axios.post(`${baseUrl}/request-install`, { model_id: model.model_id });

        this.models[index].requesting = false;
        this.models[index].requestSent = true;
      } catch (error) {
        console.error('Error requesting install:', error);
        this.models[index].requesting = false;
      }
    },
    isChecked(index) {
      console.log("checked");
      return this.models[index].checked;

      
    },
    installApplication() {
      if (!this.hasCheckedModels) {
        return;
      }
      this.openModal('ModalTrainingInstallStepOne');
    }
  }
};
</script>

<style scoped>
.main-content-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-direction: column;
}
.main-left-column, .main-right-column {
  flex: 1;
}
@media (max-width: 768px) {
  .main-content-container {
    flex-direction: column;
  }
}
.main-left-column {
  border-radius: 10px;
  overflow: hidden;
  margin-right: 40px;
  color: #3E4450;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  margin-left: 2%;
}
.main-right-column {
  display: flex;
  flex-direction: column;
}
.use-case-row {
  display: flex;
  gap: 30px;
}
.use-case-column {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.use-case-clickable {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 50%;
}
.use-case-icon {
  width: 30%;
}
.use-case-title {
  color: #3E4450;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.use-case-header {
  margin-bottom: 40px;
  color: #14C871;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.checked {
  fill: #14C871;
}
.request-install-button.highlight {
  border: 2px solid blue;
  transition: border 0.3s ease-in-out;
}
.install-button-parent {
  display: flex;
  flex-direction: row;
  padding: 3px;
}
.install-button, .install-button-2, .install-button-3 {
  flex-grow: 1;
}
.install-button {
  display: flex;
  align-items: center;
  height: 20px;
  border-radius: 27.5px;
  background: #14C871;
  padding: 15px;
}
.install {
  text-align: right;
  background: #14C871;
  width: 30%;
}
.icon-install {
  text-align: left;
  padding: 2%;
}
.icon-install svg {
  fill: black;
}
</style>
