<template>
    <div>
        <custom-modal>
            <template #header>
                <div class="modal-header-content  ml-4 mb-4 ">
                    <h3 class="header-title  no-wrap">Add a domain name</h3>
                    <button class="close-button" @click="closeModal">
                        <i class="pi pi-times close-icon"></i>
                    </button>
                </div>
            </template>
            <template #body>
                <div class="ml-4 mr-4">
                
                    <div>
                        <label>Domain name</label>
                        <InputText v-model="domain_name" placeholder=""  class="rounded-full w-full mb-2 mt-2 p-2 p-inputtext" />
                       
                    </div>
                   
                </div>
            </template>
            <template #footer>
                <div v-if="loading" class="flex align-items-center justify-start mt-2 ml-4 mb-4 font-bold border-round m-2">
                    <ProgressSpinner class="spinner" />
				</div>
                <div v-else class="flex align-items-center justify-end mt-2 ml-4 mb-4 font-bold border-round m-2 mt-4">
                    <Button v-if="!loading" label="Next" @click="next" class=" p-button-success p-button-rounded mr-2 btn-cancel" />
                  
                </div>
            </template>
        </custom-modal>
    </div>
</template>
<script>    

import CustomModal from '../commons/modal/ModalComponent.vue'
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import { mapActions } from 'vuex';

export default {
    name:'ModalAddDomainVue',

    data() {
        return {
            loading: false,
            domain_name: '',

        }
    },
    components: {
        CustomModal,
        Button,
        InputText,
        ProgressSpinner,
    },
    // created() {
    //     this.loadFormData();
    // },
    computed: {
        showModal() {
			return this.$store.state.showModal;
		},
		currentModal() {
			return this.$store.state.currentModal;
		},
    hasCheckedModels() {
      return this.$store.state.recommendedModels.some(model => model.checked);
    },
    },
    methods: {
        ...mapActions(['saveDomainName']),

        closeModal() {
            console.log('this domain',this.domain_name)
            this.$store.commit('closeModalDomain');
            this.domain_name = ''; // Clear input after sav
            //this.$store.commit('openModal','ModalVerifyOwnership');

            //this.$emit('close-modal');
        },
        loadFormData() {
            const savedFormData = JSON.parse(localStorage.getItem('formData'));
            if (savedFormData && savedFormData.trainingInstall) {
                this.region = savedFormData.trainingInstall.region || '';
                this.expected_traffic = savedFormData.trainingInstall.expected_traffic || 0;
                this.datasetSize = savedFormData.trainingInstall.datasetSize || '';

            }
        },
        next() {
            // Perform any necessary logic before moving to the next step
            console.log('Moving to next step...');
            this.saveDomainName(this.domain_name);

            
            // Close current modal and open ModalVerifyOwnership
            this.closeModal(); // Close ModalAddDomainVue
            this.$store.commit( 'showModalVerifyOwnership',true); // Open ModalVerifyOwnership
        },

    }

}
</script>
<style scoped>
.modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header-title {
    font-size: 24px; 
    font-weight: 700; 
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    vertical-align: baseline;
    margin: 0; 
    width: 333px;
    height: 17px;
    top: 396px;
    left: 645px;
}
.close-button {
    border: 2px solid red;
    background-color: red;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -38px;
    left: 100%;
    right: -12px;
    cursor: pointer;
    padding: 0;
}

@media (max-width: 400px) {
       .close-button {
        border: 2px solid red;
        background-color: red;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -38px;
        left: 100%;
        right: -12px;
        cursor: pointer;
        padding: 0;
        }
    }

.close-icon {
    color: black;
    font-size: 20px;
}
.p-inputtext {
    border-radius: 9999px;
}
.spinner {
    width: 30px;
    height: 30px;
    justify-content: start;
    margin-left: 20px;
    --p-progress-spinner-color: green;
}
.no-wrap {
    white-space: nowrap;
}
.error {
    border: 1px solid red;
}
.error-message {
    color: red;
}
</style>