<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App'
	};
</script>

<style>
	/* Global styles, if necessary */
	.main-theme-color {
		color: #14c871 !important;
	}
	.bg-theme-color {
		background-color: #14c871;
	}
	.border-theme-color {
		border: 1px solid #14c871;
	}
	.color-black {
		color: black;
	}
	.rounded-left {
		border-top-left-radius: 2rem !important;
		border-bottom-left-radius: 2rem !important;
	}
	.rounded-right {
		border-top-right-radius: 2rem !important;
		border-bottom-right-radius: 2rem !important;
	}
	.no-list-style {
		list-style: none;
		text-decoration: none;
	}
</style>
