<template>
  <section class="showcase">
    <div>
      <h3>Cost Breakdown</h3>
      <ul>
        <li>
          Our service fee $250 
        </li>
        <li>
          State fee: ${{ stateFee / 100 || 'N/A' }}
          </li>
          {{ discount }}
          <li> Total fees: ${{ (totalPrice - - discount)/100 }}</li>
          <li v-if="discount">Discount Applied: -${{ discount / 100 }}</li>
          <li v-if="discount">New Total: ${{ (totalPrice - discount) / 100 }}</li>
      </ul>
    </div>
    <div class="nes-container with-title">
      <h3>Form your dream company for only {{totalPrice/100}}</h3>
      <div class="img">
        <img
          src="@/assets/img/payment.png"
          alt="Donkey Kong Country"
        />
      </div>
    </div>
    <!-- Coupon Input -->
    <div class="coupon-container">
        <input type="text" v-model="couponCode" placeholder="Enter coupon code" class="coupon-input" />
        <button @click="validateCoupon" class="coupon-button">Apply Coupon</button>
        <div v-if="couponMessage" class="coupon-message">{{ couponMessage }}</div>
      </div>
  <div class="nes-container with-title is-centered">
    <form @submit.prevent="handleSubmit">
      <fieldset :class="{ dis: loading }" class="fields">
        <div class="nes-field">
          <label for="name_field">Name</label>
          <input
            placeholder="Jane Doe"
            type="text"
            name="name"
            id="name_field"
            class="nes-input"
          />
        </div>
        <div class="nes-field">
          <label for="email_field">Email</label>
          <input
            placeholder="jane.doe@example.com"
            type="email"
            name="email"
            id="email_field"
            class="nes-input"
          />
        </div>
        <div class="nes-field">
          <label for="address_field">Address</label>
          <input
            placeholder="1234 Sycamore Street"
            type="text"
            name="address"
            id="address_field"
            class="nes-input"
          />
        </div>
        <div class="nes-field">
          <label for="city_field">City</label>
          <input
            placeholder="Reno"
            type="text"
            name="city"
            id="city_field"
            class="nes-input"
          />
        </div>
        <div class="nes-field">
          <label for="state_field">State</label>
          <input
            placeholder="Nevada"
            type="text"
            name="state"
            id="state_field"
            class="nes-input"
          />
        </div>
        <div class="nes-field">
          <label for="zip_field">Zip</label>
          <input
            placeholder="89523"
            type="text"
            name="zip"
            id="zip_field"
            class="nes-input"
          />
        </div>
        <div class="nes-field">
          <label for="stripe-element-mount-point">Credit Card</label>
          <div id="stripe-element-mount-point"></div>
        </div>
      </fieldset>
      <div class="nes-field">
       

        <button
          type="submit"
          class="nes-btn is-primary"
          :class="{ dis: loading }"
        >
        {{ loading ? "Loading..." : `Pay $${((totalPrice - (discount || 0)) / 100).toFixed(2)}` }}
      </button>
      </div>
    </form>
  </div>
  </section>


 
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { onMounted, ref,computed } from "vue";
import { useRouter } from "vue-router";
import stateFees from './stateFees.json';

export default {
 
  setup() {
    const displayedTotalPrice = computed(() => {
      return ((totalPrice.value - (discount.value || 0)) / 100).toFixed(2);
    });
    const router = useRouter();
    let stripe = null;
    let loading = ref(true);
    let elements = null;
    let totalPrice = ref(199); // Base price in cents
    let stateFee = ref(0); // State fee in cents
    let companyName = ref(localStorage.getItem('companyName') || ''); // Retrieve companyName from localStorage
    let members = ref([]); // Store retrieved members
    let discount = ref(0); // Discount in cents
    let couponCode = ref(''); // Coupon code entered by the user
    let couponMessage = ref(''); 

    onMounted(async () => {
      const ELEMENT_TYPE = "card";
      const STRIPE_PUBLIC_KEY = 'pk_live_51MnMGrE1uOh1UBiwgHnSC8b8OKZhFEuSGIHjTa88gUmJ82JADCkaadlPd0tOrjc6JfrLkGSOkV714SgnV8QkvlKF00PrqzpBgj';

      stripe = await loadStripe(STRIPE_PUBLIC_KEY);

      elements = stripe.elements();
      const element = elements.create(ELEMENT_TYPE);
      element.mount("#stripe-element-mount-point");
      loading.value = false;

            // Retrieve selected state and members from localStorage
      const storedMembers = localStorage.getItem('members');
      if (storedMembers) {
        members.value = JSON.parse(storedMembers); // Parse and store members
      }
      const storedState = localStorage.getItem('selectedState');

      // Retrieve and update the price based on the selected state from localStorage

      if (storedState) {
        updatePrice(storedState);
      }
    });

    function updatePrice(state) {
      if (state in stateFees.stateFees) {
        const fee = parseFloat(stateFees.stateFees[state].fee);

        if (!isNaN(fee)) {
          totalPrice.value = (250 +fee) * 100; // Update totalPrice using .value for reactivity
          stateFee.value = fee * 100;   // Update stateFee using .value for reactivity
        } else {
          console.error(`Invalid fee for state: ${state}`);
          totalPrice.value = 199900; // Default price in cents if fee is not a valid number
          stateFee.value = 0;        // Default state fee if invalid
        }
      } else {
        console.error(`State ${state} not found in stateFees`);
        totalPrice.value = 199900; // Default price in cents if state is not found
        stateFee.value = 0;        // Default state fee if state not found
      }
    }
    function validateCoupon() {
      if (couponCode.value === "DISCOUNT50") {
        console.log('validate coupon',couponCode.value )

        discount.value = 5000; // Applying a discount of $50
        couponMessage.value = "Coupon applied successfully! $50 off.";
      } else if (couponCode.value === "WELCOME20") {
        discount.value = 2000; // Applying a discount of $10
        couponMessage.value = "Coupon applied successfully! $10 off.";
      } else {
        discount.value = 0;
        couponMessage.value = "Invalid coupon code.";
      }
    }

    async function handleSubmit(event) {
  if (loading.value) return;
  loading.value = true;

  // Retrieve the state from localStorage
  const selectedState = localStorage.getItem('selectedState');
  console.log("Selected State:", selectedState);

  const { name, email, address, city, zip } = Object.fromEntries(
    new FormData(event.target)
  );

  const billingDetails = {
    name,
    email,
    address: {
      city,
      line1: address,
      state: selectedState,
      postal_code: zip
    }
  };

  const cardElement = elements.getElement("card");

  try {
    const paymentMethodReq = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingDetails
    });

    const paymentMethodId = paymentMethodReq.paymentMethod.id;

    const response = await fetch("http://0.0.0.0:8000/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        amount: totalPrice.value,
        currency: "usd",
        description: "Service Fee Payment",
        payment_method_id: paymentMethodId,
        customer_email: email,  // Pass the customer's email here,
        return_url: "https://globalform.us",  // Add return_url
        state:selectedState,
        members: members.value,  // Send members to the backend
        companyName: companyName.value,  // Send companyName to the backend
        coupon_code: couponCode.value



      })        
    });
    console.log('response',response)
    const { client_secret } = await response.json();
    console.log('secret',client_secret)
    await stripe.confirmCardPayment(client_secret, {
      payment_method: paymentMethodId
    });

    loading.value = false;
    router.push("/thank-you");
  } catch (error) {
    console.log(error)
    loading.value = false;
  }
}


    function redirect() {
      stripe.redirectToCheckout({
        successUrl: "http://localhost:3000/success",
        cancelUrl: "http://localhost:3000",
        lineItems: [
          {
            price: "price_0J1wDR0ADhx7uM8yPL8Wmpoq",
            quantity: 1
          }
        ],
        mode: "payment"
      });
    }

    return { redirect,couponCode,discount, loading, handleSubmit, totalPrice, stateFee, validateCoupon,displayedTotalPrice,

    };
  }
};

</script>


<style>
/* General Styles */
/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f7f7f7;
  color: #333;
}

/* Showcase Section */
.showcase {
  padding: 20px;
  text-align: center;
}

.cost-breakdown {
  margin-bottom: 20px;
}

.cost-breakdown h3 {
  color: #ee2b4d;
  margin-bottom: 10px;
}

.cost-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cost-item {
  font-size: 1.2em;
  margin-bottom: 10px;
}

/* Nes-container */
.nes-container {
  background-color: #ffffff;
  border: 2px solid #ee2b4d;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nes-container.with-title h3 {
  color: #ee2b4d;
  margin-bottom: 20px;
}

/* Image */
.img img {
  max-width: 50%; /* Adjust width here */
  height: auto;  /* Maintain aspect ratio */
  border: 2px solid #ee2b4d;
  border-radius: 10px;
}

/* Form Styles */
form {
  margin-top: 20px;
}

.nes-field {
  margin-bottom: 15px;
}

.nes-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.nes-input:focus {
  border-color: #ee2b4d;
  box-shadow: 0 0 5px #ee2b4d;
}

/* Button Styles */
.nes-btn {
  width: 100%;
  padding: 10px;
  font-weight: bold;
  background-color: #ee2b4d;
  border-color: #ee2b4d;
  color: white;
  border-radius: 5px;
}

.nes-btn:hover {
  background-color: #d12741;
  border-color: #d12741;
}

/* Loading State */
.dis {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Alternative Payment Option */
.mt {
  margin-top: 20px;
}

.nes-btn.is-success {
  background-color: #2ee356;
  border-color: #2ee356;
  color: white;
}

.nes-btn.is-success:hover {
  background-color: #28cc4d;
  border-color: #28cc4d;
}

/* Centering the Form Container */
.nes-container.is-centered {
  margin: 0 auto;
  max-width: 500px;
}

/* Style for the list container */
ul {
  list-style-type: none; /* Remove default bullets */
  padding: 0; /* Remove default padding */
  margin: 20px 0; /* Add margin for spacing */
  border: 1px solid #ddd; /* Optional: Add border around the list */
  border-radius: 5px; /* Optional: Rounded corners */
  background-color: #f9f9f9; /* Optional: Background color */
}

/* Style for each list item */
ul li {
  padding: 10px 15px; /* Add padding inside list items */
  border-bottom: 1px solid #eee; /* Add a bottom border to each item */
  color: #333; /* Text color */
  font-size: 16px; /* Font size */
}

/* Remove the bottom border for the last item */
ul li:last-child {
  border-bottom: none;
}

/* Optional: Add hover effect */
ul li:hover {
  background-color: #f1f1f1; /* Change background color on hover */
  cursor: pointer; /* Change cursor to pointer */
}
</style>
