<template>
  <div class="py-2 px-4">
    <div class="flex flex-row justify-content-start">
      <div class="main-theme-color text-3xl font-bold">Model Library</div>
    </div>

    <div class="flex flex-row justify-content-between pt-2 pb-4">
      <div class="flex align-items-center justify-content-center font-bold border-round m-2">
        <BaseInput
          v-model="filters.global.value"
          placeholder="Search model library"
        >
          <i class="pi pi-search"></i>
        </BaseInput>
      </div>
      <div class="flex align-items-center justify-content-center font-bold border-round m-2">
        <Button
          label="Use Model"
          icon="pi pi-plus-circle"
          rounded
          :class="{'bg-theme-color border-theme-color color-black': canUseModel }"
          @click="useModel"
          :disabled="!canUseModel"
        />
      </div>
    </div>
    <DataTable
      :value="models"
      tableStyle="min-width: 50rem"
      :filters="filters"
      :globalFilterFields="['model', 'category']"
      :paginator="true"
      :rows="10"
      :rowsPerPageOptions="[5, 10, 15]"
      v-model:selection="selectedModels"
      :metaKeySelection="metaKey"
      dataKey="models_id"
      @rowSelect="onRowSelect"
    >
      <template #empty>No model found.</template>
      <Column
        field="selected"
        header-style="width: 3rem; color: green"
        selectionMode="single"
      ></Column>
      <Column field="model" header="Model"></Column>
      <Column field="category" header="Category"></Column>
      <Column field="status" header="Status"></Column>
      <Column field="installed" header="Installed">
        <template #body="slotProps">
          <!-- Render "Request Install" or "Installed" based on the value of "installed" -->
          <button
            :class="{'btn-request-install': true, 'btn-request-click': selectedModels && selectedModels.models_id === slotProps.data.models_id}"
            v-if="!slotProps.data.installed"
            @click="requestedInstall(slotProps.data)"
          >
            Request Install
          </button>
          <span class="text-brand-color" v-else>Installed</span>
        </template>
      </Column>
      <Column field="action" header="Action">
        <template #body="slotProps">
          <Button
            @click="openMenu($event, slotProps.data)"
            class="main-theme-color"
            type="button"
            text
            rounded
          >
            <i class="pi pi-ellipsis-h" style="font-size: 1.5rem"></i>
          </Button>
          <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
        </template>
      </Column>
    </DataTable>
  </div>

  <!-- All Modals -->
  <modal-training-install-step-one
    v-if="showModal && currentModal === 'ModalTrainingInstallStepOne'"
    @next="openModal('ModalTrainingInstallPreferenceStepOne')"
    @close-modal="closeModal"
  />
  <modal-training-install-preference-step-one
    v-if="showModal && currentModal === 'ModalTrainingInstallPreferenceStepOne'"
    @previous="openModal('ModalTrainingInstallStepOne')"
    @next="openModal('ModalTrainingSelectServerStepOne')"
    @close-modal="closeModal"
  />
  <modal-plan
    v-if="showModal && currentModal === 'ModalPlan'"
    @previous="openModal('ModalTrainingInstallPreferenceStepOne')"
    @next="openModal('ModalTrainingSelectServerStepOne')"
    @close-modal="closeModal"
  />
  <modal-training-select-server-step-one
    v-if="showModal && currentModal === 'ModalTrainingSelectServerStepOne'"
    @previous="openModal('ModalTrainingInstallPreferenceStepOne')"
    @next="openModal('ModalTrainingInstallEndStepOne')"
    @close-modal="closeModal"
  />
  <modal-plan-price-and-payement
    v-if="showModal && currentModal === 'ModalPlanPriceAndPayement'"
    @previous="openModal('ModalTrainingSelectServerStepOne')"
    @next="openModal('ModalTrainingInstallEndStepOne')"
    @close-modal="closeModal"
  />
  <modal-training-install-end-step-one
    v-if="showModal && currentModal === 'ModalTrainingInstallEndStepOne'"
    @previous="openModal('ModalTrainingSelectServerStepOne')"
    @close-modal="closeModal"
  />
</template>

<script setup>
import { ref, computed } from 'vue';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import BaseInput from "../components/BaseInput";
import Menu from "primevue/menu";
import { getModels, requestInstall } from "../services/model.service"; // Assuming you have a requestInstall method in your service
import { FilterMatchMode } from "primevue/api";
import { useStore } from "vuex";

// Import All Modal Training
import ModalTrainingInstallStepOne from '../components/training/ModalTrainingInstallStepOne.vue'
import ModalTrainingSelectServerStepOne from '../components/training/ModalTrainingSelectServerStepOne .vue'
import ModalTrainingInstallPreferenceStepOne from '../components/training/ModalTrainingInstallPreferenceStepOne.vue'
import ModalPlan from '../components/training/ModalPlan.vue'
import ModalPlanPriceAndPayement from '../components/training/ModalPlanPriceAndPayement.vue'
import ModalTrainingInstallEndStepOne from '../components/training/ModalTrainingInstallEndStepOne.vue'

const models = ref([]);
const filters = ref(null);
const selectedModels = ref();
const store = useStore();
const metaKey = ref(true);

const items = [
  {
    label: "Edit",
    icon: "pi pi-pencil",
    command: () => editModelLibrary(selectedModels.value),
  },
  {
    label: "Delete",
    icon: "pi pi-trash",
    command: () => deleteModelLibrary(selectedModels.value),
  },
];

const canUseModel = computed(() => {
  return selectedModels.value && selectedModels.value.installed != '';
});

const onRowSelect = (event) => {
  console.log('Selected model ID:', selectedModels.value.models_id);
  localStorage.setItem('selectedModelId', selectedModels.value.models_id);


  selectedModels.value = event.data;
};

const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  };
};

const openMenu = (event, model) => {
  selectedModels.value = model;
  console.log('selected model',selectedModels)
  ref.menu.toggle(event);
};

const editModelLibrary = (model) => {
  // Handle the edit action with the selected model
  console.log("Edit model", model);
};

const deleteModelLibrary = (model) => {
  // Handle the delete action with the selected model
  console.log("Delete model", model);
};

const openModal = (modal) => {
  store.commit('openModal', modal);
};

const closeModal = () => {
  store.commit('closeModal');
};

const useModel = () => {
  openModal('ModalTrainingInstallStepOne');
  return store.state.showModal;
};

const showModal = computed(() => {
  return store.state.showModal;
});

const currentModal = computed(() => {
  return store.state.currentModal;
});

const requestedInstall = async (event) => {
  return await requestInstall(event);
};

const setActiveMenu = () => store.dispatch("setActiveMenu", "model-library");

(async () => {
  setActiveMenu();
  initFilters();
  try {
    models.value = await getModels();
  } catch (error) {
    console.error("Error fetching models:", error);
  }
})();
</script>

<style>
.text-brand-color {
  color: green;
}

.btn-request-install {
  background-color: green;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.btn-request-click {
  background-color: rgb(52, 179, 52);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  animation: pulsate 1s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 140, 186, 0.7);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(0, 140, 186, 0.7);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(1, 95, 43, 0.7);
  }
}

.ui-datatable .ui-state-highlight {
  background-color: green !important;
}
</style>
