<template>
  <div class="flex flex-row px-4 py-2">
    <div class="w-12">
      <Card class="w-12 bg-gradient h-7rem border-round-xl pt-1 relative">
        <template #content>
          <div class="absolute top-50 left-5 overflow-hidden border-circle">
            <div class="profil-avatar">
              <img
                src="../assets/img/profil.jpeg"
                alt="Profile Picture"
                class="w-full h-full"
              />
            </div>
          </div>
        </template>
      </Card>
      <div class="flex flex-row relative">
        <div class="profil-name profil-name-position absolute">John Stone</div>
      </div>
      <div class="grid pt-7">
        <div class="col-12 md:col-8">
          <Card class="w-12 border-round-xl pt-1 px-4">
            <template #title>
              <div class="flex flex-row justify-content-between">
                <div class="card-title">MY PROFILE</div>
                <div>
                  <Button
                    v-if="!isEditing"
                    class="bg-theme-color border-theme-color"
                    size="large"
                    icon="pi pi-ellipsis-h"
                    @click="toggleContextMenu"
                    aria-haspopup="true"
                    aria-controls="overlay_menu"
                    rounded
                    type="button"
                    aria-label="Option"
                  />
                  <Menu
                    ref="menu"
                    id="overlay_menu"
                    :model="items"
                    :popup="true"
                  />
                  <Button
                    label="Submit"
                    icon="pi pi-check"
                    @click="submitProfile"
                    v-if="isEditing"
                  />
                </div>
              </div>
            </template>
            <template #content>
              <div>
                <div class="grid">
                  <div class="col-12 md:col-6 flex flex-column gap-2">
                    <div>
                      <div>Name :</div>
                      <div v-if="!isEditing" class="text-user-info">
                        {{ user.name }}
                      </div>
                      <InputText
                        class="rounded-left rounded-right"
                        v-else
                        v-model="user.name"
                      />
                    </div>
                    <div>
                      <div>Tel :</div>
                      <div v-if="!isEditing" class="text-user-info">
                        {{ user.tel }}
                      </div>
                      <InputText
                        class="rounded-left rounded-right"
                        v-else
                        v-model="user.tel"
                      />
                    </div>
                    <div>
                      <div>Mail :</div>
                      <div v-if="!isEditing" class="text-user-info">
                        {{ user.mail }}
                      </div>
                      <InputText
                        class="rounded-left rounded-right"
                        v-else
                        v-model="user.mail"
                      />
                    </div>
                  </div>
                  <div class="col-12 md:col-6 flex flex-column gap-2">
                    <div>
                      <div>Address :</div>
                      <div v-if="!isEditing" class="text-user-info">
                        {{ user.address }}
                      </div>
                      <InputText
                        class="rounded-left rounded-right"
                        v-else
                        v-model="user.address"
                      />
                    </div>
                    <div>
                      <div>City :</div>
                      <div v-if="!isEditing" class="text-user-info">
                        {{ user.city }}
                      </div>
                      <InputText
                        class="rounded-left rounded-right"
                        v-else
                        v-model="user.city"
                      />
                    </div>
                    <div>
                      <div>Country :</div>
                      <div v-if="!isEditing" class="text-user-info">
                        {{ user.country }}
                      </div>
                      <InputText
                        class="rounded-left rounded-right"
                        v-else
                        v-model="user.country"
                      />
                    </div>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-6"></div>
                  <div class="col-6"></div>
                </div>
              </div>
              <Divider />
              <div>
                <div class="card-title">CONNECTION AND SAFETY</div>
                <div>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation
                </div>
                <div class="pt-3">
                  <Button
                    iconPos="right"
                    label="Define new password"
                    icon="pi pi-arrow-up-right"
                    rounded
                    class="bg-theme-color border-theme-color color-black"
                  />
                </div>
              </div>
              <Divider />
              <div>
                <div class="card-title">NOTIFICATIONS</div>

                <div
                  class="notification-switch mt-3 flex flex-row flex-wrap justify-content-between align-items-center"
                >
                  <div>Do you want to receive notifications</div>
                  <div>
                    <InputSwitch
                      inputClass="main-theme-color"
                      v-model="checked"
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div>
                <ConfirmDialog></ConfirmDialog>
                <div class="card-title">DELETE ACCOUNT</div>
                <div>Do you want to delete your account</div>
                <div class="pt-3">
                  <Button
                    @click="confirmAction"
                    iconPos="right"
                    label="Delete Account"
                    icon="pi pi-arrow-up-right"
                    rounded
                    class="bg-red-theme-color border-red-theme-color color-black"
                  />
                </div>
              </div>
            </template>
          </Card>
        </div>
        <div class="col-12 md:col-4">
          <Card class="w-12 h-20rem border-round-xl px-4 py-2 bg-gray-card">
            <template #title><div>TRAFFIC</div></template>
            <template #content>
              <div>4500USD</div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Divider from "primevue/divider";
import InputSwitch from "primevue/inputswitch";
import Menu from "primevue/menu";
import { getUser, updateUser } from "../services/user.service";
import ConfirmDialog from "primevue/confirmdialog";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
import { useRouter } from "vue-router";

export default {
  name: "ProfilPage",
  setup() {
    // Reactive data
    const checked = ref(false);
    const isEditing = ref(false);
    const store = useStore();
    const router = useRouter();
    const user = ref({});
    const confirm = useConfirm();
    const items = ref([
      {
        label: "Edit Profile",
        icon: "pi pi-pencil",
        command: () => editUserProfile(),
      },
      {
        label: "Change Picture",
        icon: "pi pi-user-edit",
        command: () => changePicture(),
      },
    ]);

    // Methods
    const confirmAction = () => {
      confirm.require({
        message: "Are you sure you want to delete your account?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => handleConfirmAccepted(),
        reject: () => {},
      });
    };

    const editUserProfile = () => {
      isEditing.value = true;
    };

    const changePicture = () => {
      // Change picture logic
    };

    const submitProfile = () => {
      updateUser(user.value.id, user.value);
      isEditing.value = false;
    };

    const handleConfirmAccepted = () => {
      router.push("/login");
    };

    const setActiveMenu = (menu) => {
      store.dispatch("setActiveMenu", menu);
    };

    onMounted(async () => {
      user.value = await getUser(1);
      setActiveMenu("Profil");
    });

    return {
      checked,
      isEditing,
      items,
      user,
      editUserProfile,
      changePicture,
      submitProfile,
      handleConfirmAccepted,
      confirmAction,
    };
  },
  methods: {
    toggleContextMenu(event) {
      this.$refs.menu.toggle(event);
    },
  },
  components: {
    Card,
    Button,
    Divider,
    InputSwitch,
    Menu,
    InputText,
    ConfirmDialog,
  },
};
</script>

<style>
.p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:hover)
  .p-inputswitch-slider {
  background-color: #14c871;
}

.p-inputswitch.p-highlight .p-inputswitch-slider {
  background-color: #14c871;
}

.profil-avatar {
  width: 7rem;
  height: 7rem;
  font-size: 4rem;
  border-radius: 50%;
  background-color: #cccccc;
}

.profil-name-position {
  top: 85%;
  left: 50%;
}

/* Small (sm) */
@media (min-width: 640px) {
  .profil-name-position {
    top: 85%;
    left: 30%;
  }
}

/* Medium (md) */
@media (min-width: 768px) {
  .profil-name-position {
    top: 85%;
    left: 27%;
  }
}

/* Large (lg) */
@media (min-width: 1024px) {
  .profil-name-position {
    top: 85%;
    left: 20%;
  }
}

/* Extra large (xl) */
@media (min-width: 1280px) {
  .profil-name-position {
    top: 85%;
    left: 16%;
  }
}

.top-75 {
  top: 75%;
}
.top-85 {
  top: 85%;
}
.top-60 {
  top: 60%;
}
.left-5 {
  left: 5%;
}

.profil-name {
  font-size: 1.5rem;
  font-weight: 700;
}
.notification-switch {
  padding: 0.75rem;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #cccccc;
  border-radius: 35px;
}
.card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.main-theme-color {
  color: #14c871;
}
.bg-red-theme-color {
  background-color: #d42d2d;
}
.border-red-theme-color {
  border: 1px solid #d42d2d;
}
.red-theme-color {
  color: #d42d2d;
}
.text-user-info {
  color: #707077;
  font-weight: 650;
}

.bg-gray-card {
  background-color: #f4f4f4;
}
.bg-gradient {
  background: rgb(20, 200, 113);
  background: linear-gradient(
    90deg,
    rgba(20, 200, 113, 1) 0%,
    rgba(111, 136, 12, 1) 100%
  );
}
.color-black {
  color: black;
}
.rounded-left {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
.rounded-right {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
</style>
