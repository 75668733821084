<template>
    <div>
        <custom-modal v-if="showModalTraining">
            <template #header>
                <div class="modal-header-content  ml-4 mb-4 ">
                    <h3 class="header-title  no-wrap">TRAINING DATA STRUCTURE </h3>
                    <button class="close-button" @click="closeModal">
                        <i class="pi pi-times close-icon"></i>
                    </button>
                </div>
            </template>
            <template #body>
                <div class="ml-4  grid grid-cols-2 gap-2">
                    <div class="">
                        <label class="">Output Directory</label>
                        <InputText v-model="output_dir" placeholder="Output Directory" class="rounded-full w-full mb-2 mt-2 p-inputtext" />
                        <br>
                        <label class="">Number of Training Epochs</label>
                        <InputText v-model="num_train_epochs" placeholder="Number of Training Epochs" class="rounded-full w-full mb-2 mt-2 p-inputtext" />
                        <br>
                        <label class="">Per Device Training Batch Size</label>
                        <InputText v-model="per_device_train_batch_size" placeholder="Per Device Training Batch Size" class="rounded-full w-full mb-2 mt-2 p-inputtext" />
                        <br>
                        <label class="">Gradient Accumulation Steps</label>
                        <InputText v-model="gradient_accumulation_steps" placeholder="Gradient Accumulation Steps" class="rounded-full w-full mb-2 mt-2 p-inputtext" />
                        <br>
                        <input type="checkbox" id="gradient_checkpointing" v-model="gradient_checkpointing" class="mt-2 mb-2" />
                        <label class="ml-2 mb-2">Gradient check pointing</label>
                        <br>
                        <label class="">Optimizer</label>
                        <InputText v-model="optim" placeholder="Optimizer" class="rounded-full w-full mb-2 mt-2 p-inputtext" />
                        <br>
                        <label class="">Logging Steps</label>
                        <InputText v-model="logging_steps" placeholder="Logging Steps" class="rounded-full w-full mb-2 mt-2 p-inputtext" />
                        <br>
                        <label class="">Learning Rate Scheduler Type</label>
                        <InputText v-model="lr_scheduler_type" placeholder="Learning Rate Scheduler Type" class="rounded-full w-full mb-2 mt-2 p-inputtext" />
                        <br>
                      
                    </div>
                    <div class="">
                        <label class="">Save Strategy</label>
                        <InputText v-model="save_strategy" placeholder="Save Strategy" class="rounded-full w-full mb-2 mt-2 p-inputtext" />
                        <br>
                        <label class="">Learning Rate</label>
                        <InputText v-model="learning_rate" placeholder="Learning Rate" class="rounded-full w-full mb-2 mt-2 p-inputtext" />
                        <br>
                        <input type="checkbox" id="fp16" v-model="fp16" class="mt-2 mb-2" />
                        <label class=" ml-2">fp16</label>
                        <br>
                        <label class="">Max Gradient Norm</label>
                        <InputText v-model="max_grad_norm" placeholder="Max Gradient Norm" class="rounded-full w-full mb-2 mt-2 p-inputtext" />
                        <br>
                        <label class="">Warmup Ratio</label>
                        <InputText v-model="warmup_ratio" placeholder="Warmup Ratio" class="rounded-full w-full mb-2 mt-2 p-inputtext" />
                        <br>
                        <div>
                            <template v-if="disable_tqdm === 'LLAMA 2'">
                            <input type="checkbox" id="disable_tqdm" v-model="disable_tqdm" class="mt-2 mb-2" />
                            <label for="disable_tqdm" class="ml-2">Disable TQDM</label>
                            </template>
                            <template v-else>
                            <label for="disable_tqdm" class="ml-2">Disable TQDM</label>
                            <input type="text" v-model="disable_tqdm" class="rounded-full w-full mt-2 p-inputtext" />
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div v-if="loading" class="flex align-items-center justify-start mt-2 ml-4 mb-4 font-bold border-round m-2">
                    <ProgressSpinner class="spinner" />
				</div>
                <div v-else class="flex align-items-center justify-start mt-2 ml-4 mb-4 font-bold border-round m-2 mt-4">
                    <Button label="Save" @click="handleSubmit" icon="pi pi-arrow-up-right" rounded class="bg-theme-color color-black save-btn" />
				</div>
            </template>
        </custom-modal>
    </div>
</template>
<script>    
import CustomModal from '../commons/modal/ModalComponent.vue'
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name:'ModalTrainingData',
    props: {
            showModal: Boolean
        },
    data() {
        return {
            loading: false,
            output_dir: "finetuned-llama-7b-chat-hf-med",
            num_train_epochs: 3,
            per_device_train_batch_size: 4,
            gradient_accumulation_steps: 2,
            gradient_checkpointing: true,
            optim: "paged_adamw_32bit",
            logging_steps: 10,
            save_strategy: "epoch",
            learning_rate: 2e-4,
            fp16: true,
            max_grad_norm: 0.3,
            warmup_ratio: 0.03,
            lr_scheduler_type: "constant",
            disable_tqdm: true
        }
    },
    components: {
        CustomModal,
        Button,
        InputText,
        ProgressSpinner
    },
    methods: {
        closeModal() {
            this.$emit('close-modal');
        },
        handleSubmit() {
            this.loading = true
            const formData = {
                output_dir: "finetuned-llama-7b-chat-hf-med",
                num_train_epochs: this.num_train_epochs,
                per_device_train_batch_size: this.per_device_train_batch_size,
                gradient_accumulation_steps: this.gradient_accumulation_steps,
                gradient_checkpointing: this.gradient_checkpointing,
                optim: this.optim,
                logging_steps: this.logging_steps,
                save_strategy: this.save_strategy,
                learning_rate: this.learning_rate,
                fp16: this.fp16,
                max_grad_norm: this.max_grad_norm,
                warmup_ratio: this.warmup_ratio,
                lr_scheduler_type: this.lr_scheduler_type,
                disable_tqdm: this.disable_tqdm
            };
            console.log('Form data:', formData);
            // this.$emit('submit', formData);

            setTimeout(() => {
                this.closeModal();
                this.loading = false;
            }, 2000);
        }
    }

}
</script>
<style scoped>
.modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header-title {
    font-size: 24px; 
    font-weight: 700; 
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    vertical-align: baseline;
    margin: 0; 
    width: 333px;
    height: 17px;
    top: 396px;
    left: 645px;
}
.close-button {
    border: 2px solid red;
    background-color: red;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -38px;
    left: 100%;
    right: -12px;
    cursor: pointer;
    padding: 0;
}

@media (max-width: 400px) {
       .close-button {
        border: 2px solid red;
        background-color: red;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -38px;
        left: 100%;
        right: -12px;
        cursor: pointer;
        padding: 0;
        }
    }

.close-icon {
    color: black;
    font-size: 20px;
}
.p-inputtext {
    border-radius: 9999px;
}
.spinner {
    width: 30px;
    height: 30px;
    justify-content: start;
    margin-left: 20px;
    --p-progress-spinner-color: green;
}
.no-wrap {
    white-space: nowrap;
}
</style>