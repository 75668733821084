<template>
    <div class="thank-you-page">
      <h1 class="text-3xl font-bold text-center">Thank You!</h1>
      <p class="text-lg text-center mt-4">Your payment was successful.</p>
    </div>
  </template>
  
  <script setup>
  
  </script>
  
  <style scoped>
  .thank-you-page {
    max-width: 600px;
    margin: auto;
    padding: 40px 20px;
    text-align: center;
  }
  </style>
  