<template>
    <div class="main-content-container">
     
      <div class="main-left-column">
        <div class="business-info">
          <img src="@/assets/img/meeting.png" alt="Logo" class="business-info" />
        </div>
      </div>
      <div class="main-right-column">
        <div class="email-input-section" v-if="!isEmailValid">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            v-model="email"
            :class="['email-field', { error: emailError }]"
          />
          <button class="next-button-1" @click="validateEmail">Next</button>
          <p v-if="emailError" class="error-message">Please enter a valid email address.</p>
          </div>
      <div class="company-info"  v-if="isEmailValid">
        <div class="use-case-header">Company name</div>
  
        <div class="use-case-row">
          <div class="use-case-column">
            <input
              type="text"
              placeholder="Enter company name here"
              v-model="companyName"
              :class="['busines-field', { error: companyNameError }]"
            />
          </div>
          <div class="use-case-column">
            <!-- Add other fields if necessary -->
          </div>
        </div>
        <div class="use-case-row">
          <div class="use-case-column">
            <div class="use-case-icon"><i class="fas fa-building"></i></div>
            <div class="use-case-content">
              <div class="use-case-title">Add LLC to Business Name</div>
              <div class="use-case-text business-name">
                Ensure your business name stands out by appending "LLC" to the end,
                signifying a Limited Liability Company and providing your business with
                a professional touch.
              </div>
            </div>
          </div>
          <div class="use-case-column">
            <div class="use-case-icon video-icon"><i class="fas fa-file-video"></i></div>
            <div class="use-case-column">
              <div class="use-case-content">
                <div class="use-case-title special">No Special Characters in Business Name</div>
                <div class="use-case-text special-text">
                  Ensure your business name contains no special characters, keeping it clean
                  and professional. This helps in maintaining a straightforward and
                  recognizable brand identity.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="use-case-header">State to incorporate</div>
        <div class="use-case-row">
          <div class="use-case-column">
            <div class="input-group">
              <label for="state-select">Select State:</label>
              <select
                id="state-select"
                v-model="selectedState"
                :class="['state-select', { error: stateError }]"
              >
              <option value="" disabled>Select a state</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
          </div>
          <div class="use-case-column">
            <!-- Add other fields if necessary -->
          </div>
        </div>
  
        <div class="use-case-row">
          <div class="use-case-column">
            <div class="use-case-icon"></div>
            <div class="use-case-content">
              <div class="use-case-title"></div>
              <div class="use-case-text"></div>
            </div>
          </div>
          <div class="use-case-column">
            <div class="use-case-icon"></div>
            <div class="use-case-column">
              <div class="use-case-content">
                <button class="next-button-1" :disabled="!isFormValid" @click="handleNextClick">Next</button>
              </div>
            </div>
          </div>
          </div>
        </div>
        <!-- Additional use cases if needed -->
      </div>
    </div>
  </template>
  
  <script setup>
  import '@fortawesome/fontawesome-free/css/all.css';
  import { ref, computed, watch } from 'vue';
  import { useStore } from 'vuex'; // Import Vuex useStore function if using Vuex
  import axios from 'axios'
  
  const store = useStore(); // Assuming you are using Vuex and have imported useStore
  
  const companyName = ref(localStorage.getItem('companyName') || '');
  const selectedState = ref(localStorage.getItem('selectedState') || '');
  
  const companyNameError = computed(() => {
    return companyName.value.trim() === '';
  });
  const email = ref('');
const emailError = ref(false);
const isEmailValid = ref(false);

const validateEmail = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailPattern.test(email.value)) {
    console.log('is email valid',isEmailValid)
    emailError.value = false;
    isEmailValid.value = true; // Hides the email input and shows the form
     // Send request to FastAPI backend
     axios.post('http://localhost:8000/start-email-scheduling', {
      recipient_email: email.value
    })
    .then(response => {
      console.log('Email sent for backend processing:', response.data);
    })
    .catch(error => {
      console.error('Error sending email to backend:', error);
    });
  } else {
    emailError.value = true;
  }
};

  
  const stateError = computed(() => {
    return selectedState.value.trim() === '';
  });
  
  const isFormValid = computed(() => {
    return !companyNameError.value && !stateError.value;
  });
  
  // Watch for changes to companyName and selectedState to save them to localStorage
  watch(companyName, (newValue) => {
    localStorage.setItem('companyName', newValue);
    console.log('Company Name saved to localStorage:', newValue); // Log to test
  });
  
  watch(selectedState, (newValue) => {
    localStorage.setItem('selectedState', newValue);
    console.log('Selected State saved to localStorage:', newValue); // Log to test
  });
  
  const goToStep = (step) => {
    store.commit('setStep', step); // Committing mutation to set step in Vuex store
    store.commit('updateCurrentComponent', 'UploadFile');
  };
  
  const handleNextClick = () => {
    console.log('Company Name on Next Click:', companyName.value); // Log to test
    goToStep(2); // Call goToStep directly in handleNextClick
  };
  </script>
  
  
  
<style scoped>
.error {
  border-color: red !important; /* Ensure the error style is applied */
}
	main-content-container {
		display: flex;
		gap: 20px; /* Adjust the space between the columns as necessary */
		margin-top: 20px; /* Adds some space above the container */
		flex-direction: column;
	}

	.main-left-column,
	.main-right-column {
		flex: 1; /* This makes each column take up equal space */
		margin-left:24px;
	}

	@media (max-width: 768px) {
  
		.main-content-container {
			flex-direction: column; /* Stacks the columns vertically on smaller screens */
		}
	}
	.main-left-column {
		border-radius: 10px; /* Adjust the value as needed */
		overflow: hidden; /* Ensures the iframe content stays within the rounded border */
		margin-right: 40px;
	}

	.video-iframe {
		width: 100%; /* Ensures the iframe fills its container */
		height: 100%; /* Ensures the iframe fills its container */
	}
	.main-right-column {
		display: flex;
		flex-direction: column;
	}

	.use-case-row {
		display: flex;
		gap: 30px; /* Adjust the spacing between columns */
	}

	.use-case-column {
		flex: 1;
		display: flex;
		flex-direction: row; /* Ensure columns stack vertically */
		margin-bottom: 24px;
	}

	.use-case-icon {
		margin-right: 5px; /* Add some space between the icon and content */
	}

	.icon-color {
		color: #746;
	}
	.use-case-icon {
		color: #746;
	}

	.use-case-title {
		color: #3e4450;

		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.use-case-header {
		margin-bottom: 40px;
		color: #ee2b4d;

		font-family: Poppins;
		font-size: 22px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.use-case-text {
		font-size: 14px;
	}

	.business-info {
		width: 100px; 
	}
	.busines-field {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  border: 2px solid white;
  border-radius: 8px;
  box-sizing: border-box;
  height: 50px; /* Increase the height */
  background-color: #f0f0f0; /* Optional: Background color */
  color: #333; /* Optional: Text color */
}


.state-select {
  width: 100%;
  padding: 12px;
  border: 2px solid white;
  border-radius: 12px;
  box-sizing: border-box;
  font-size: 16px;
  height: 60px; /* Increase height */
  margin-top: 10px; /* Add margin for spacing */
}

.input-group {
  margin-bottom: 20px;
}

.email-input-section {
  margin-bottom: 20px;
}

.email-input-section label {
  display: block;
  margin-bottom: 8px; /* Add space between the label and input field */
  font-size: 16px; /* Optional: adjust font size if needed */
  color: #333; /* Optional: adjust color */
}

.email-input-section .email-field {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 2px solid white;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 12px; /* Optional: space between the input and the next button */
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* Styles for the Next button */
.next-button-1 {
  background-color: #ee2b4d;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
}

.next-button-1:hover {
  background-color: #45a049;
}
@media(max-width: 479px) {
  .business-name{
    display:none
  }
  .special-text,.special{
    display:none
  }
  .video-icon{
      display:none
    }
  .busines-field{
    width:153px;
  }
  .state-select{
    width:153px;
  }
}

</style>
