<template>
  <div class="py-2 px-4">
    <div class="flex flex-row justify-content-start">
      <div class="main-theme-color text-3xl font-bold">Data Management</div>
    </div>

    <div class="flex flex-row justify-content-between pt-2 pb-4">
      <div class="flex align-items-center justify-content-center font-bold border-round m-2">
        <BaseInput v-model="filters.global.value" placeholder="Search model library">
          <i class="pi pi-search"></i>
        </BaseInput>
      </div>
      <div class="flex align-items-center justify-content-center font-bold border-round m-2">

      </div>
    </div>

    <div class="flex flex-row justify-start pb-4">
      <!-- Add workflow button with conditions -->
      <div class="flex align-items-center justify-content-center font-bold border-round m-2">
        <Button label="Tous" rounded 
        :class="['border-theme-color',  {'color-black bg-theme-color ' : currentFilter === 'all'} , {'bg-bouton' : currentFilter !== 'all'}, 'py-3', 'px-5']"
          @click="() => filterManagement('all')" />
      </div>
      <div class="flex align-items-center justify-content-center font-bold border-round m-2">
        <Button label="Row Data" rounded 
        :class="['border-theme-color',  {'color-black bg-theme-color ' : currentFilter === 'raw'} , {'bg-bouton' : currentFilter !== 'raw'}, 'py-3', 'px-5']"
          @click="() => filterManagement('raw')" />
      </div>
      <div class="flex align-items-center justify-content-center font-bold border-round m-2">
        <Button label="Polished" rounded 
        :class="['border-theme-color',  {'color-black bg-theme-color ' : currentFilter === 'polished'} , {'bg-bouton' : currentFilter !== 'polished'}, 'py-3', 'px-5']"
          @click="() => filterManagement('polished')" />
      </div>
    </div>

    <div class="flex flex-wrap justify-content-start pt-2 pb-4 gap-8 mx-auto">
      <div class="container-content  flex flex-column font-bold " v-for="(management, index) in filteredManagements"
        :key="index">
        <Button class="main-theme-color  flex justify-content-end mt-2" type="button" text rounded>
          <i class="pi pi-ellipsis-v" style="font-size: 1.5rem"></i>
        </Button>

        <div class="container-section ml-4 flex justify-content-center align-items-center"
          v-if="management.fileType == 'CSV'">
          <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="3" ry="3" fill="white" />
            <path d="M14 2v6h6" fill="#e2e3e5" />
            <path d="M14 2l6 6h-6z" fill="white" />
            <text x="12" y="18" fill="#28a745" font-family="Arial, sans-serif" font-size="8" text-anchor="middle"
              font-weight="bold">CSV</text>
          </svg>
        </div>
        <div class="container-section pdf ml-4 flex justify-content-center align-items-center"
          v-if="management.fileType == 'PDF'">
          <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

            <rect width="24" height="24" rx="3" ry="3" fill="white" />

            <path d="M14 2v6h6" fill="white" />
            <path d="M14 2l6 6h-6z" fill="#adb5bd" />

            <text x="12" y="18" fill="#dc3545" font-family="Arial, sans-serif" font-size="8" text-anchor="middle"
              font-weight="bold">PDF</text>
          </svg>
        </div>


        <div class="container-section doc ml-4 flex justify-content-center align-items-center"
          v-if="management.fileType == 'DOC'">
          <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

            <rect width="24" height="24" rx="3" ry="3" fill="white" />

            <path d="M14 2v6h6" fill="white" />
            <path d="M14 2l6 6h-6z" fill="#adb5bd" />
            <!-- Word Text -->
            <text x="12" y="18" fill="#0078D4" font-family="Arial, sans-serif" font-size="8" text-anchor="middle"
              font-weight="bold">DOC</text>
          </svg>
        </div>


        <div class="container-section xls ml-4 flex justify-content-center align-items-center"
          v-if="management.fileType == 'XLS'">
          <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

            <rect width="24" height="24" rx="3" ry="3" fill="white" />

            <path d="M14 2v6h6" fill="#white" />
            <path d="M14 2l6 6h-6z" fill="#adb5bd" />
            <!-- Excel Text -->
            <text x="12" y="18" fill="#217346" font-family="Arial, sans-serif" font-size="8" text-anchor="middle"
              font-weight="bold">XLS</text>
          </svg>
        </div>

        <div class="container-section jpg ml-4 flex justify-content-center align-items-center"
          v-if="management.fileType == 'JPG'">
          <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

            <rect width="24" height="24" rx="3" ry="3" fill="white" />

            <path d="M14 2v6h6" fill="#e2e3e5" />
            <path d="M14 2l6 6h-6z" fill="#adb5bd" />
            <!-- JPG Text -->
            <text x="12" y="18" fill="#ff9800" font-family="Arial, sans-serif" font-size="8" text-anchor="middle"
              font-weight="bold">JPG</text>
          </svg>
        </div>

        <div class="ml-4">
          <p class="">{{ management.fileName }}</p>
          <p class="subtext-detail">{{ management.fileSize }}</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import BaseInput from "../components/BaseInput";
import { FilterMatchMode } from "primevue/api";
import Button from "primevue/button";
import store from "@/store";
//import { getAllDataManagement } from "@/services/model.service";

const filters = ref(null);
const managements = ref([
  {
    "user_id": "user123",
    "fileName": "Patient Records.CSV",
    "fileType": "CSV",
    "fileSize": "15 Mo",
    "status": "raw"
  },
  {
    "user_id": "user123",
    "fileName": "Patient Records.CSV",
    "fileType": "CSV",
    "fileSize": "18 Mo",
    "status": "polished"
  },
  {
    "user_id": "user456",
    "fileName": "Clinical Data.XLS",
    "fileType": "XLS",
    "fileSize": "20 Mo",
    "status": "raw"
  },
  {
    "user_id": "user456",
    "fileName": "Clinical Data.XLS",
    "fileType": "XLS",
    "fileSize": "22 Mo",
    "status": "polished"
  },
  {
    "user_id": "user789",
    "fileName": "Financial Records.DOC",
    "fileType": "DOC",
    "fileSize": "25 Mo",
    "status": "raw"
  },
  {
    "user_id": "user789",
    "fileName": "Financial Records.CSV",
    "fileType": "CSV",
    "fileSize": "28 Mo",
    "status": "polished"
  },
  {
    "user_id": "user789",
    "fileName": "Sales Data.PDF",
    "fileType": "PDF",
    "fileSize": "30 Mo",
    "status": "raw"
  },
  {
    "user_id": "user789",
    "fileName": "Sales Data.JPG",
    "fileType": "JPG",
    "fileSize": "32 Mo",
    "status": "polished"
  }

 ])
const currentFilter = ref('all');
const filteredManagements = ref([
{
    "user_id": "user123",
    "fileName": "Patient Records.CSV",
    "fileType": "CSV",
    "fileSize": "15 Mo",
    "status": "raw"
  },
  {
    "user_id": "user123",
    "fileName": "Patient Records.CSV",
    "fileType": "CSV",
    "fileSize": "18 Mo",
    "status": "polished"
  },
  {
    "user_id": "user456",
    "fileName": "Clinical Data.XLS",
    "fileType": "XLS",
    "fileSize": "20 Mo",
    "status": "raw"
  },
  {
    "user_id": "user456",
    "fileName": "Clinical Data.XLS",
    "fileType": "XLS",
    "fileSize": "22 Mo",
    "status": "polished"
  },
  {
    "user_id": "user789",
    "fileName": "Financial Records.DOC",
    "fileType": "DOC",
    "fileSize": "25 Mo",
    "status": "raw"
  },
  {
    "user_id": "user789",
    "fileName": "Financial Records.CSV",
    "fileType": "CSV",
    "fileSize": "28 Mo",
    "status": "polished"
  },
  {
    "user_id": "user789",
    "fileName": "Sales Data.PDF",
    "fileType": "PDF",
    "fileSize": "30 Mo",
    "status": "raw"
  },
  {
    "user_id": "user789",
    "fileName": "Sales Data.JPG",
    "fileType": "JPG",
    "fileSize": "32 Mo",
    "status": "polished"
  }
]);

const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  };
};

const filterManagement = (filter) => {
  currentFilter.value = filter;
  if (currentFilter.value === 'all') {
    return  filteredManagements.value = managements.value;
  }
   return filteredManagements.value = managements.value.filter(management => management.status === currentFilter.value);
};

const setActiveMenu = () => store.dispatch("setActiveMenu", "data-management");

(async () => {
  setActiveMenu();
  initFilters();
  try {
     //managements.value = await getAllDataManagement()
  } catch (error) {
    console.error("Error fetching models:", error);
  }
})();
</script>

<style>
.container-content {
  width: 220px;
  height: 200px;
  border-radius: 13px;
}

.container-section {
  width: 90px;
  height: 200px;
  background: #066838;
  border-radius: 13px;
}

.subtext-detail {
  color: #393b3a;
  font-weight: lighter;
}

.pdf {
  background-color: #dc3545;
}

.doc {
  background-color: #0078D4;
}

.xls {
  background-color: #217346;
}

.jpg {
  background-color: #ff9800;
}

.bg-bouton {
  background-color: white;
  color: black;
}
</style>