<template>
    <div>
        <custom-modal v-if="showModal">
            <template #header>
                <div class="modal-header-content ml-4 mb-4">
                    <h3 class="header-title no-wrap">YOUR PLAN</h3>
                    <button class="close-button" @click="closeModal">
                        <i class="pi pi-times close-icon"></i>
                    </button>
                </div>
            </template>
            <template #body>
                <div class="ml-4 mr-4">
                    <div class="items-center">
                        <div class="justify-content-center items-center ml-5">
                            <div class="custom-checkbox" :class="{ 'checked': shared_server }" @click="shared_server = !shared_server">
                                <input type="checkbox" v-model="shared_server"  class="p-checkbox" />
                                <label>Shared server</label>
                            </div>
                        </div>
                    </div>
                    <div class="items-center">
                        <div class="justify-content-center items-center ml-5">
                            <div class="custom-checkbox" :class="{ 'checked': startup }" @click="startup = !startup">
                                <input type="checkbox" v-model="startup" class="p-checkbox" />
                                <label>Startup</label>
                            </div>
                        </div>
                    </div>
                    <div class="items-center">
                        <div class="justify-content-center items-center ml-5">
                            <div class="custom-checkbox" :class="{ 'checked': entreprise }" @click="entreprise = !entreprise">
                                <input type="checkbox" v-model="entreprise" class="p-checkbox" />
                                <label>Entreprise</label>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div v-if="loading" class="flex align-items-center justify-start mt-2 ml-4 mb-4 font-bold border-round m-2">
                    <ProgressSpinner class="spinner" />
                </div>
                <div v-else class="flex align-items-center justify-start mt-2 ml-4 mb-4 font-bold border-round m-2 mt-4">
                    <Button v-if="!loading" label="Previous" @click="previous" class="p-button-outlined p-button-rounded mr-2 btn-cancel" />
                    <Button label="Next" @click="handleSubmit" :icon="loading ? '' : 'pi pi-arrow-right'" :disabled="loading" rounded class="bg-theme-color color-black save-btn" />
                 
                </div>
            </template>
        </custom-modal>
    </div>
</template>
<script>    
import CustomModal from '../commons/modal/ModalComponent.vue'
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name:'ModalPlan',
    data() {
        return {
            loading: false,
            entreprise: false,
            startup: false,
            shared_server: false
        }
    },
    components: {
        CustomModal,
        Button,
        ProgressSpinner
    },
    computed: {
        showModal() {
            return this.$store.state.showModal;
        }
    },
    methods: {
        previous() {
            this.$emit('previous');
        },
        closeModal() {
            this.$emit('close-modal');
        },
        handleSubmit() {
            this.loading = true
            // this.$emit('submit', formData);

            const formData = {
                entreprise: this.entreprise,
                startup: this.startup,
                shared_server: this.shared_server
            };
            this.$store.dispatch('saveFormData', { trainingInstallYourPlan : formData });
            this.$emit('next');
        }
    }

}
</script>
<style scoped>
.modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header-title {
    font-size: 24px; 
    font-weight: 700; 
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    vertical-align: baseline;
    margin: 0; 
    width: 333px;
    height: 17px;
    top: 396px;
    left: 645px;
}
.close-button {
    border: 2px solid red;
    background-color: red;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -38px;
    left: 100%;
    right: -12px;
    cursor: pointer;
    padding: 0;
}

@media (max-width: 400px) {
       .close-button {
        border: 2px solid red;
        background-color: red;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -38px;
        left: 100%;
        right: -12px;
        cursor: pointer;
        padding: 0;
        }
    }

.close-icon {
    color: black;
    font-size: 20px;
}
.p-inputtext {
    border-radius: 9999px;
}
.spinner {
    width: 30px;
    height: 30px;
    justify-content: start;
    margin-left: 20px;
    --p-progress-spinner-color: green;
}
.no-wrap {
    white-space: nowrap;
}

.btn-cancel {
    background-color: transparent;
    color: #14C871;
    border: 1px solid;
}

.custom-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: -40px;
    cursor: pointer;
    border-radius: 9999px;
    border: 1px solid #ccc; /* Bordure par défaut */
    padding: 10px; /* Ajout de padding pour améliorer l'apparence */
    background-color: #F0F0F0;
}

.custom-checkbox.checked {
    border-color: rgba(20, 200, 113, 0.1); /* Couleur de la bordure en vert lorsque sélectionné */
    background-color: rgba(20, 200, 113, 0.1);
}

.custom-checkbox .p-checkbox {
    margin-right: 5px;
    border-color: #14C871; 
}

.custom-checkbox label {
    cursor: pointer;
    color: black; /* Texte du label en noir par défaut */
    margin-left: 10px;
}

.custom-checkbox input[type="checkbox"] {
    display: none; /* Cacher le checkbox par défaut */
}

.custom-checkbox label {
    cursor: pointer;
    color: black; /* Texte du label en noir par défaut */
    margin-right: 5px; /* Espacement entre le checkbox et le label */
}

.custom-checkbox input[type="checkbox"] + label::before {
    content: "";
    display: inline-block;
    width: 10px; /* Largeur du cercle */
    height: 10px; /* Hauteur du cercle */
    border-radius: 50%; /* Rendre le cercle circulaire */
    border: 1px solid #ccc; /* Bordure grise par défaut */
    margin-right: 10px;
}

.custom-checkbox input[type="checkbox"]:checked + label::before {
    background-color: #14C871; /* Couleur verte lorsque sélectionné */
    border-color: #14C871; /* Couleur de la bordure verte lorsque sélectionné */
}
</style>