<template>
  <div>
      <custom-modal v-if="showModal">
          <template #header>
              <div class="modal-header-content  ml-4 mb-4 ">
                  <h3 class="header-title  no-wrap">PLAN PRICE AND PAYEMENT</h3>
                  <button class="close-button" @click="closeModal">
                      <i class="pi pi-times close-icon"></i>
                  </button>
              </div>
          </template>
          <template #body>
            <div class="container">
                <h4 class="font-bold price-label">Price : <span class="price-text">$ {{ planPrice }}</span></h4>
                <div class="relative-container">
                <label for="cardNumber" class="floating-label">Credit Card Number</label>
                <InputText
                    id="cardNumber"
                    class="rounded-full w-full mb-2 mt-2 p-inputtext"
                    :class="{ error: cardNumberError }"
                    v-model="cardNumber"
                    placeholder="000 000 000 000"
                    @input="formatCreditCardNumber"
                    required
                />
                </div>

                <div class="combined-input">
                <div class="relative-container input-wrapper">
                    <label for="expirationDate" class="floating-label"
                    >Valid date</label
                    >
                    <InputText
                    id="expirationDate"
                    class="rounded-full w-full mb-2 mt-2 p-inputtext"
                    :class="{ error: expirationDateError }"
                    v-model="expirationDate"
                    placeholder="Monthe / Years"
                    required
                    @input="formatExpirationDate"
                    />
                </div>
                <div class="relative-container input-wrapper ml-4">
                    <label for="cvv" class="floating-label">CVV</label>
                    <InputText
                        id="cvv"
                        type="password"
                        placeholder="*****"
                        class="password-input rounded-full w-full mb-2 mt-2 p-inputtext"
                        :class="{ error: cvvError }"
                        v-model="cvv"
                        required
                        />
                </div>
                </div>
            </div>
          </template>
          <template #footer>
                <div v-if="loading" class="flex align-items-center justify-start ml-4 mb-4 font-bold border-round m-2">
                    <ProgressSpinner class="spinner" />
                </div>
                <div v-else class="flex align-items-center justify-end mt-2 ml-4 mb-4 font-bold border-round m-2">
                    <Button v-if="!loading" label="Cancel" @click="previous"  class="p-button-outlined  p-button-danger p-button-rounded mr-2 btn-cancel font-bold" />
                    <Button label="Next" @click="handleSubmit"  :icon="loading ? '' : 'pi pi-arrow-right'" :disabled="loading" rounded class="bg-theme-color color-black save-btn font-bold" />
                </div>
            </template>
        </custom-modal>
    </div>
</template><script>
import { mapActions } from 'vuex';
import CustomModal from '../commons/modal/ModalComponent.vue';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

export default {
  name: 'ModalPlanPriceAndPayement',
  data() {
    return {
      modalTitle: 'Plan price and payment',
      loading: false,
      planPrice: 8000,
      cardNumber: '',
      expirationDate: '',
      cvv: '',

      // Validation errors
      cardNumberError: false,
      expirationDateError: false,
      cvvError: false
    };
  },
  components: {
    ProgressSpinner,
    Button,
    InputText,
    CustomModal
  },
  computed: {
    showModal() {
      return this.$store.state.showModal;
    }
  },
  methods: {
    ...mapActions(['openModal', 'closeModal']),

    formatCreditCardNumber() {
      // Remove any non-numeric characters
      let formattedCardNumber = this.cardNumber.replace(/\D/g, '');
      // Insert space after every 4 digits
      formattedCardNumber = formattedCardNumber.replace(/(.{4})/g, '$1 ').trim();
      // Update the cardNumber value
      this.cardNumber = formattedCardNumber;
    },

    formatExpirationDate() {
      // Remove any non-numeric characters
      let formattedExpirationDate = this.expirationDate.replace(/\D/g, '');
      // Limit the length to 6 characters (MMYYYY)
      formattedExpirationDate = formattedExpirationDate.slice(0, 6);
      // Format the expiration date as "MM/YYYY" or "MM/YY"
      if (formattedExpirationDate.length >= 2) {
        formattedExpirationDate = formattedExpirationDate.replace(/^(.{2})/, '$1/');
      }
      // Update the expirationDate value
      this.expirationDate = formattedExpirationDate;
    },

    handleSubmit() {
      // Validation checks
      if (!this.cardNumber.trim()) {
        this.cardNumberError = true;
        return;
      } else {
        this.cardNumberError = false;
      }

      if (!this.expirationDate.trim()) {
        this.expirationDateError = true;
        return;
      } else {
        this.expirationDateError = false;
      }

      if (!this.cvv.trim()) {
        this.cvvError = true;
        return;
      } else {
        this.cvvError = false;
      }
      const formData = {
                cardNumber: this.cardNumber,
                expirationDate: this.expirationDate,
                cvv: this.cvv
            };
            console.log(formData)
      // Form submission logic
      this.loading = true;
      // Simulate form submission del ay
      setTimeout(() => {
        this.loading = false;
        // Dispatch action to save form data
        const formData = {
          cardNumber: this.cardNumber,
          expirationDate: this.expirationDate,
          cvv: this.cvv
        };
        this.$store.dispatch('saveFormData', { trainingPayement: formData });
        // Emit event to move to the previous step
        this.$emit('previous');
      }, 2000);
    }
  }
};
</script>

  
  <style scoped>
.container {
    padding: 10px 30px 10px 30px;
    margin-top: -5%;
}
  
.modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header-title {
    font-size: 24px; 
    font-weight: 700; 
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    vertical-align: baseline;
    margin: 0; 
    width: 333px;
    height: 17px;
    top: 396px;
    left: 645px;
}

.close-button {
    border: 2px solid red;
    background-color: red;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -38px;
    left: 100%;
    right: -12px;
    cursor: pointer;
    padding: 0;
}

@media (max-width: 400px) {
       .close-button {
        border: 2px solid red;
        background-color: red;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -38px;
        left: 100%;
        right: -12px;
        cursor: pointer;
        padding: 0;
        }
    }

.close-icon {
    color: black;
    font-size: 20px;
}
.p-inputtext {
    border-radius: 1999px;
}
.spinner {
    width: 30px;
    height: 30px;
    justify-content: start;
    margin-left: 20px;
    --p-progress-spinner-color: green;
}
.no-wrap {
    white-space: nowrap;
}

.error-message {
    color: red;
}
.font-bold {
    font-weight: bold;
}
.price-label {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.price-text {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #14C871; 
}
.relative-container {
  position: relative;
  margin-bottom: 20px;
}

.floating-label {
  position: absolute;
  top: -10px;
  left: 40px;
  background-color: white;
  padding: 0 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #333;
  transition: all 0.2s ease-out;
}

.relative-container input {
  padding-top: 15px;
  padding-left: 15px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}

.relative-container input:focus + .floating-label,
.relative-container input:not(:placeholder-shown) + .floating-label {
  top: -30px;
  font-size: 12px;
  color: #14c871;
}

.error {
  border-color: red;
}

.combined-input {
  display: flex;
  gap: 1rem;
}

.input-wrapper {
  position: relative;
  flex: 1;
}

.ml-4 {
  margin-left: 1rem;
}
  </style>