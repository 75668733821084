<template>
    <div>
        <custom-modal v-if="showModal">
            <template #header>
                <div class="modal-header-content  ml-4 mb-4 ">
                    <h3 class="header-title  no-wrap">SELECT YOUR AUDIENCE</h3>
                    <button class="close-button" @click="closeModal">
                        <i class="pi pi-times close-icon"></i>
                    </button>
                </div>
            </template>
            <template #body>
                <div class="ml-4 mr-4">
                    <div>
                        <label>Region</label>
                        <select v-model="region" :class="{ 'error': regionError }" class="rounded-full w-full mb-2 mt-2 p-inputtext">
                            <option value="" disabled>Select region</option>
                            <option v-for="reg in regions" :key="reg" :value="reg">{{ reg }}</option>
                        </select>
                        <span v-if="regionError" class="error-message">Region is required</span>
                    </div>
                    <div>
                        <label>Expected Traffic</label>
                        <InputText v-model.number="expected_traffic" placeholder="Expected traffic" :class="{ 'error': trafficError }" class="rounded-full w-full mb-2 mt-2 p-inputtext" />
                        <span v-if="trafficError" class="error-message">Expected traffic must be a number no more than 10,000,000,000</span>
                    </div>
                    <div>
                        <label>Dataset Size</label>
                        <select v-model="datasetSize" :class="{ 'error': datasetSizeError }" class="rounded-full w-full mb-2 mt-2 p-inputtext">
                            <option value="" disabled>Select dataset size</option>
                            <option v-for="size in datasetSizes" :key="size" :value="size">{{ size }}</option>
                        </select>
                        <span v-if="datasetSizeError" class="error-message">Dataset size is required</span>
                    </div>
                </div>
            </template>
            <template #footer>
                <div v-if="loading" class="flex align-items-center justify-start mt-2 ml-4 mb-4 font-bold border-round m-2">
                    <ProgressSpinner class="spinner" />
				</div>
                <div v-else class="flex align-items-center justify-end mt-2 ml-4 mb-4 font-bold border-round m-2 mt-4">
                    <Button v-if="!loading" label="Cancel" @click="closeModal" class="p-button-outlined p-button-danger p-button-rounded mr-2 btn-cancel font-bold" />
                    <Button label="Next"  @click="next"  :icon="loading ? '' : 'pi pi-arrow-right'" :disabled="loading" rounded class="bg-theme-color color-black save-btn font-bold" />
                </div>
            </template>
        </custom-modal>
    </div>
</template>
<script>    
import CustomModal from '../commons/modal/ModalComponent.vue'
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name:'ModalTrainingInstallStepOne',
    data() {
        return {
            loading: false,
            regions: ['Europe', 'North America', 'South America', 'Asia', 'Africa'],
            datasetSizes: ['1 - 5,000 lines', '5,000 - 10,000 lines', '10k - 50,000', '50k - 100k', '100,000+'],
            datasetSize: '',

            expected_traffic: 0,
            region: '',
            regionError: false,
            trafficError: false,
            datasetSizeError: false

        }
    },
    components: {
        CustomModal,
        Button,
        InputText,
        ProgressSpinner,
    },
    created() {
        this.loadFormData();
    },
    computed: {
        showModal() {
            return this.$store.state.showModal;
        },
    },
    methods: {
        openModal(modal) {
			this.$store.commit('openModal', modal);
		},
        closeModal() {
            this.$emit('close-modal');
        },
        loadFormData() {
            const savedFormData = JSON.parse(localStorage.getItem('formData'));
            if (savedFormData && savedFormData.selectYourAudiance) {
                this.region = savedFormData.selectYourAudiance.region || '';
                this.expected_traffic = savedFormData.selectYourAudiance.expected_traffic || 0;
                this.datasetSize = savedFormData.selectYourAudiance.datasetSize || '';

            }
        },
        next() {
            this.loading = true
            if (!this.region) {
                this.regionError = true;
                this.loading = false
                return;
            } else {
                this.regionError = false;
                this.loading = false
            }
            if(!this.datasetSize) {
                this.datasetSizeError = true
                this.loading = false
                return;
            } else {
                this.datasetSizeError = false
                this.loading = false
            }
            // Validation for Expected Traffic
            const trafficRegex = /^\d+$/;
            this.trafficError = !this.expected_traffic || !trafficRegex.test(this.expected_traffic) || Number(this.expected_traffic) > 10000000000;

            // Stop submission if there are errors
            if (this.keyNameError || this.regionError || this.trafficError) {
                this.loading = false;
                return;
            }
            const formData = {
                region: this.region,
                expected_traffic: this.expected_traffic,
                datasetSize: this.datasetSize
            };
            console.log(formData)
            this.$store.dispatch('saveFormData', { selectYourAudiance : formData });
            this.$emit('next');

        }
    }
}
</script>
<style scoped>
.modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header-title {
    font-size: 24px; 
    font-weight: 700; 
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    vertical-align: baseline;
    margin: 0; 
    width: 333px;
    height: 17px;
    top: 396px;
    left: 645px;
}
.close-button {
    border: 2px solid red;
    background-color: red;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -38px;
    left: 100%;
    right: -12px;
    cursor: pointer;
    padding: 0;
}

@media (max-width: 400px) {
       .close-button {
        border: 2px solid red;
        background-color: red;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -38px;
        left: 100%;
        right: -12px;
        cursor: pointer;
        padding: 0;
        }
    }

.close-icon {
    color: black;
    font-size: 20px;
}
.p-inputtext {
    border-radius: 9999px;
}
.spinner {
    width: 30px;
    height: 30px;
    justify-content: start;
    margin-left: 20px;
    --p-progress-spinner-color: green;
}
.no-wrap {
    white-space: nowrap;
}
.error {
    border: 1px solid red;
}
.error-message {
    color: red;
}
</style>