<template>
    <main class="app-main-content">
      <div v-if="loading">
        <ProgressSpinner class="spinner" />
      </div>
      <!-- <BaseInput v-else class="mt-3 mb-6" @button-clicked="sendRequest()" v-model="requirement" placeholder="Have an idea? Let us know" :is-valid="isValid">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
          <path d="M3.85291 1H16.8036V13.8571" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M1.69458 16L16.8037 1" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </BaseInput> -->
      <div v-if="loading" class="columns-container-spinner">
        <p class="container-spinner"></p>
      </div>
      <div v-else class="columns-container">
        <div class="steps" :style="{ width: '25%', maxWidth: '600px' }">
          <div class="column">
            <div class="row header">
              <SvgIcon label="1" :active="currentStep" :stepNumber="1">1</SvgIcon>
            </div>
            <div class="actions">
              <div class="row header-label">STEP</div>
              <div class="row content">Business name</div>
            </div>
          </div>
  
          <div class="column">
            <div class="row header"></div>
            <div class="actions">
              <div class="row header">
                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="83" viewBox="0 0 4 83" fill="none">
                  <path d="M2 2L2 81" stroke="#858EA1" stroke-width="3" stroke-linecap="round" />
                </svg>
              </div>
              <div class="row content"></div>
            </div>
          </div>
          <!-- Repeat columns as needed -->
          <div class="column">
            <div class="row header">
              <SvgIcon label="2" :active="currentStep" :stepNumber="2">2</SvgIcon>
            </div>
            <div class="actions">
              <div class="row header-label">Step</div>
              <div class="row content">Company info</div>
            </div>
          </div>
          <div class="column">
            <div class="row header"></div>
            <div class="actions">
              <div class="row header-label">
                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="83" viewBox="0 0 4 83" fill="none">
                  <path d="M2 2L2 81" stroke="#858EA1" stroke-width="3" stroke-linecap="round" />
                </svg>
              </div>
              <div class="row content"></div>
            </div>
          </div>
          <div class="column">
            <div class="row header">
              <SvgIcon label="3" :active="currentStep" :stepNumber="3">3</SvgIcon>
            </div>
            <div class="actions">
              <div class="row header-label">Step</div>
              <div class="row content">Business owner</div>
            </div>
          </div>
  
          <div class="column">
            <div class="row header"></div>
            <div class="actions">
              <div class="row header-label">
                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="83" viewBox="0 0 4 83" fill="none">
                  <path d="M2 2L2 81" stroke="#858EA1" stroke-width="3" stroke-linecap="round" />
                </svg>
              </div>
              <div class="row content"></div>
            </div>
          </div>
  
          <div class="column">
            <div class="row header">
              <SvgIcon label="4" :active="currentStep" :stepNumber="4">4</SvgIcon>
            </div>
  
            <div class="actions">
              <div class="row header-label">Step</div>
              <div class="row content">Payment</div>
            </div>
          </div>
        </div>
        <component
          :is="currentComponent"
          :recommendedModels="recommendedModels"
          v-if="currentComponent !== 'UploadFile' && currentComponent !== 'testingphase' && currentComponent !== 'deploy'"
        />
        <!-- Render the UploadFile component conditionally -->
        <UploadFile v-else-if="currentComponent === 'UploadFile'" />
        <!-- Render the TestingPhase component conditionally -->
        <TestingPhase v-else-if="currentComponent === 'testingphase'" />
        <!-- Render the DeployContent component conditionally -->
        <DeployContent v-else-if="currentComponent === 'deploy'" />
      </div>
    </main>
  </template>
  

  <script>
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import '@fortawesome/fontawesome-free/css/all.css';
  import BaseContent from '../maincontent/BaseContent.vue'; // Adjust the import path as necessary
  import ModelToUseContent from '../maincontent/ModelToUseContent.vue';
  import UploadFile from '../maincontent/UploadFile.vue';
  import TestingPhase from '../maincontent/testModel.vue';
  import DeployContent from '../maincontent/DeployContent.vue'; // Ensure correct import path
  import ProgressSpinner from 'primevue/progressspinner';
  import SvgIcon from '../tools/SvgIcons.vue';
  import BaseInput from './BaseInput';
  import { recommendAiModel } from "../services/modelrecommended.service.js";
  
  export default {
    name: 'AppMainContent',
    props: {
      label: String,
      active: Boolean
    },
    setup() {
      const store = useStore();
      const currentStep = computed(() => {
        console.log('Accessing currentStep:', store.state.currentStep);
        return store.state.currentStep;
      });
  
      console.log('current step', currentStep);
      const currentComponent = computed(() => store.state.currentComponent);
      const stepsWidth = computed(() => store.state.stepsWidth);
      const requirement = ref('');
      const loading = ref(false);
      const isValid = ref(true);
  
      const sendRequest = async() => {
        if (!requirement.value.trim()) {
          isValid.value = false;
          return;
        }
        loading.value = true;
        isValid.value = true;
  
        try {
          const response = await recommendAiModel(requirement.value);
          console.log('Response:', response);
          if (Array.isArray(response)) {
            const truncatedResults = response.map(model => console.log(model));
            console.log('Truncated Results:', truncatedResults);
            store.commit('updateRecommendedModels', response);
          } else {
            console.error('Response is not an array:', response);
          }
          store.commit('updateCurrentComponent', 'ModelToUseContent');
          store.commit('updateStepsWidth', '20%');
        } catch (error) {
          console.error('Error:', error);
        } finally {
          loading.value = false;
        }
      };
  
      return {
        requirement,
        loading,
        currentStep,
        sendRequest,
        currentComponent,
        stepsWidth,
        isValid
      };
    },
    components: {
      BaseContent,
      BaseInput,
      ModelToUseContent,
      SvgIcon,
      UploadFile,
      TestingPhase,
      ProgressSpinner,
      DeployContent
    }
  };
  </script>
  
  <style scoped>
.input-wrapper {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: relative;
  width: 100%;
  margin-bottom: 60px;
}

.full-width-input {
  width: 100%;
  padding: 10px 50px 10px 20px;
  border-radius: 32.5px;
  border: 1px solid #d3cece;
  background: #fff;
  box-sizing: border-box;
}

.full-width-input:focus {
  border: 1px solid #14c871; /* Custom border color for focus state */
  outline: none; /* Remove default outline */
}

.icon-circle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #14c871;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.app-main-content {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  width: 100%;
  margin: 0 auto;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  z-index: 9999; /* Ensure spinner appears on top */
}

.columns-container-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; /* Full height of the container */
}

.columns-container {
  display: flex;
  width: 100%;
  margin-top: 40px;
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  flex-shrink: 0; /* Prevent the sidebar from shrinking */
  width: 25%;
  max-width: 600px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.row.header-label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.row.content {
  font-size: 0.875rem;
  color: #333;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 300px; /* Adjust as needed */
}

input[type="text"]:focus {
  border-color: #14c871; /* Add a custom border color on focus */
  outline: none; /* Remove the default outline */
}

/* For showing invalid input border color */
input[type="text"].invalid {
  border-color: red;
}
</style>
