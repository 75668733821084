// Import Axios if you are using it
import axios from 'axios';

// Function to fetch recommended AI models
export async function recommendAiModel(requirement) {
  try {
    const baseUrl = process.env.VUE_APP_BASE_URL; // Ensure this points to your API base URL

    const response = await axios.post(`${baseUrl}/recommend_ai_model/`, { requirement });

    if (response.status !== 200) {
      throw new Error('Error while recommending AI models');
    }
	console.log(response.data)
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}