import { createApp } from "vue";
import App from "./App.vue";
import router from "./router"; // Ensure your router is properly set up in './router/index.js'
import store from "./store"; // Ensure your store is properly set up in './store/index.js'
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css"; // theme
import "primevue/resources/primevue.min.css"; // core css
import "primeicons/primeicons.css"; // icons
import "primeflex/primeflex.css";
// server du mirage
//import { makeServer } from "./server";
import ConfirmationService from "primevue/confirmationservice";

const app = createApp(App);
//commenter le code ici si vous n'utilise pas le mirage
//  if (process.env.NODE_ENV === "development") {
// }  
//makeServer();

app.use(router); // Use Vue Router
app.use(store); // Use Vuex
app.use(PrimeVue); // Use PrimeVue
app.use(ConfirmationService);
app.mount("#app");
