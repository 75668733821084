<template>
  <div class="py-2 px-4">
    <div class="flex flex-row justify-content-start">
      <div class="main-theme-color text-3xl font-bold">Workflows</div>
    </div>

    <div class="flex flex-row justify-content-between pt-2 pb-4">
      <!-- Add workflow button with conditions -->
      <div class="flex align-items-center justify-content-center font-bold border-round m-2">
        <Button
          label="Add workflow"
          icon="pi pi-plus-circle"
          rounded
          :class="{'bg-theme-color border-theme-color color-black': canUseModel }"
          @click="addWorkflow"
          :disabled="!canUseModel"
        />
      </div>
    </div>

    <!-- DataTable for displaying models -->
    <DataTable v-if="models.length > 0"
      :modelValue="filters"
      :value="models"
      tableStyle="min-width: 50rem"
      :globalFilterFields="['model', 'category', 'status', 'installed']"
      :paginator="true"
      :rows="10"
      :rowsPerPageOptions="[5, 10, 15]"
      v-model:selection="selectedModels"
      :metaKeySelection="metaKey"
      dataKey="id"
      @rowSelect="onRowSelect"
    >
      <template #empty>No model found.</template>
      <!-- Selection column -->
      <Column
        field="selected"
        header-style="width: 3rem; color: green"
        selectionMode="single"
      ></Column>
      <!-- Model column -->
      <Column field="model" header="Input">
        <template #body="slotProps">
          <div v-if="slotProps.data.editing">
            <Dropdown v-model="slotProps.data.model" :options="modelOptions" optionLabel="label" />
            <button @click="saveModel(slotProps.data)">Save</button>
            <button @click="cancelEdit(slotProps.data)">Cancel</button>
          </div>
          <div v-else>{{ slotProps.data.model }}</div>
        </template>
      </Column>
      <!-- Category column -->
      <Column field="category" header="Model 1">
        <template #body="slotProps">
          <div v-if="slotProps.data.editing">
            <Dropdown v-model="slotProps.data.category" :options="categoryOptions" optionLabel="label" />
            <button @click="saveModel(slotProps.data)">Save</button>
            <button @click="cancelEdit(slotProps.data)">Cancel</button>
          </div>
          <div v-else>{{ slotProps.data.category }}</div>
        </template>
      </Column>
      <!-- Status column -->
      <Column field="status" header="Model 2">
        <template #body="slotProps">
          <div v-if="slotProps.data.editing">
            <Dropdown v-model="slotProps.data.status" :options="statusOptions" optionLabel="label" />
            <button @click="saveModel(slotProps.data)">Save</button>
            <button @click="cancelEdit(slotProps.data)">Cancel</button>
          </div>
          <div v-else>{{ slotProps.data.status }}</div>
        </template>
      </Column>
      <!-- Output column -->
      <Column field="installed" header="Output">
        <template #body="slotProps">
          <button
            :class="{'btn-request-install': true, 'btn-request-click': selectedModels && selectedModels.some(model => model.id === slotProps.data.id)}"
            v-if="!slotProps.data.installed"
            @click="requestedInstall(slotProps.data)"
          >
            Request Install
          </button>
          <span v-else class="text-brand-color">Installed</span>
        </template>
      </Column>
      <!-- Action column (for menu) -->
      <Column field="action" header="Action">
        <template #body="slotProps">
          <Button
            @click="openMenu($event, slotProps.data)"
            class="main-theme-color"
            type="button"
            text
            rounded
          >
            <i class="pi pi-ellipsis-h" style="font-size: 1.5rem"></i>
          </Button>
          <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
        </template>
      </Column>
    </DataTable>
    <AudioRecorder @audioProcessed="handleAudioProcessed" />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Menu from "primevue/menu";
import Dropdown from "primevue/dropdown";
import { requestInstall } from "../services/model.service";
import { FilterMatchMode } from "primevue/api";
import AudioRecorder from "@/components/AudioRecorder.vue"; // Adjust path as per your project structure

const models = ref([
  { id: 1, model: 'Whisper', category: 'OpenAI (chatgpt)', status: 'Tacotron', installed: 'Speaker', editing: false },
]);

const filters = ref(null);
const selectedModels = ref([]);
const metaKey = ref(true);
const store = useStore();

const items = [
  {
    label: "Edit",
    icon: "pi pi-pencil",
    command: () => editModelLibrary(selectedModels.value),
  },
  {
    label: "Delete",
    icon: "pi pi-trash",
    command: () => deleteModelLibrary(selectedModels.value),
  },
];

// Example model options, replace with your actual options data
const modelOptions = ref([
  { label: 'Google Speech Transcriber', value: 'Google Speech Transcriber' },
  { label: 'Other Model 1', value: 'Other Model 1' },
  { label: 'Another Model 2', value: 'Another Model 2' },
]);

const canUseModel = computed(() => {
  return selectedModels.value && selectedModels.value.installed !== '';
});

const onRowSelect = (event) => {
  selectedModels.value = event.data;
};

const openMenu = (event, model) => {
  selectedModels.value = model;
  ref.menu.toggle(event);
};

const editModelLibrary = (model) => {
  console.log("Edit model", model);
  model.editing = true;
};

const deleteModelLibrary = (model) => {
  console.log("Delete model", model);
};

const requestedInstall = async (event) => {
  return await requestInstall(event);
};

const saveModel = (model) => {
  console.log("Save model", model);
  model.editing = false;
};

const cancelEdit = (model) => {
  console.log("Cancel edit", model);
  model.editing = false; // Hide inputs by setting editing to false
};

const addWorkflow = () => {
  models.value.push({ id: models.value.length + 1, model: '', category: '', status: '', installed: '', editing: true });
};

const setActiveMenu = () => {
  store.dispatch("setActiveMenu", "workflows");
};

const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  };
};

(async () => {
  setActiveMenu();
  initFilters();
})();
</script>

<style scoped>
.text-brand-color {
  color: green;
}

.btn-request-install {
  background-color: green;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.btn-request-click {
  background-color: rgb(52, 179, 52);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  animation: pulsate 1s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 140, 186, 0.7);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(0, 140, 186, 0.7);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(1, 95, 43, 0.7);
  }
}

.ui-datatable .ui-state-highlight {
  background-color: green;
}
</style>
