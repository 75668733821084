<template>
  <div>
    <div v-if="!isRecording">
      <button @click="startRecording">Start Recording</button>
    </div>
    <div v-else>
      <button @click="stopRecording">Stop Recording</button>
      <p>Recording...</p>
    </div>
    <audio controls ref="audioPlayer" style="margin-top: 10px;" v-if="synthesizedAudio">
      <source :src="synthesizedAudio" type="audio/wav">
      Your browser does not support the audio element.
    </audio>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AudioRecorder',
  data() {
    return {
      isRecording: false,
      mediaRecorder: null,
      recordedAudioBlob: null,
      synthesizedAudio: null, // Initialize synthesizedAudio to null
    };
  },
  methods: {
    async startRecording() {
      try {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          console.error('getUserMedia is not supported in this browser');
          alert('Browser does not support audio recording.');
          return;
        }
        console.log('Requesting microphone access...');
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        const chunks = [];

        this.mediaRecorder.ondataavailable = e => {
          chunks.push(e.data);
        };

        this.mediaRecorder.onstop = async () => {
          this.recordedAudioBlob = new Blob(chunks, { type: 'audio/wav' });
          console.log('Recorded audio blob:', this.recordedAudioBlob);
          await this.uploadAudio(this.recordedAudioBlob);
        };

        this.mediaRecorder.start();
        console.log('Recording started...');
        this.isRecording = true;
      } catch (err) {
        console.error('Error accessing microphone:', err);
      }
    },
    stopRecording() {
      if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
        this.mediaRecorder.stop();
        console.log('Recording stopped.');
        this.isRecording = false;
      }
    },
    async uploadAudio(blob) {
      try {
        console.log('Preparing to upload audio...');
        const formData = new FormData();
        formData.append('audio', blob, 'audio.wav');

        const transcriptionService = 'whisper';
        const url = `https://sh.islandaiforge.us/process_audio?transcription_service=${encodeURIComponent(transcriptionService)}`;

        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data && response.data.transcription) {
          console.log('Transcribed text:', response.data.transcription);
          this.synthesizedAudio = `data:audio/wav;base64,${response.data.audio}`; // Set the synthesized audio URL
        } else {
          console.error('Unexpected response data:', response.data);
        }
      } catch (error) {
        console.error('Error uploading audio:', error);
      }
    }
  }
};
</script>
