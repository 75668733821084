<template>
  <div class="py-2 px-4">
    <div class="flex flex-row justify-content-start">
      <div class="main-theme-color text-3xl font-bold">Test / Endpoint</div>
    </div>

    <div class="main-content-container">
      <div class="main-left-column">
        <div class="card flex flex-column md:flex-row gap-3">
          <div class="input-group">
            <input
              type="text"
              placeholder="Enter input"
              :class="['input-text rounded-full w-full font-bold', { 'border-red': isInvalid }]"
              v-model="testField"
            />
            <Button
              label="Test"
              @click="testFunction"
              icon="pi pi-arrow-up-right"
              rounded
              class="bg-theme-color color-black search-button font-bold"
            />
          </div>
        </div>
        <div class="result mt-3">
          <span v-if="result" class="result-title">Result :</span>
          <br />
          <div class="py-6 juice:py-[18px] px-3 text-base md:px-4 m-auto md:px-5 lg:px-1 xl:px-5">
            <div v-if="isLoading" class="columns-container-spinner">
              <p class="container-spinner"></p>
            </div>
            <div v-else>
              <span v-if="result">{{ result }}</span>
            </div>
          </div>
          <div class="flex align-items-center justify-end mt-2 ml-4 mb-4 font-bold border-round m-2 mt-4">
            <Button
              label="Deploy"
              @click="deploy"
              :icon="loading ? '' : 'pi pi-arrow-right'"
              :disabled="loading || isPlanIdDeactivated"
              :class="{'deactivated-button': isPlanIdDeactivated}"
              rounded
              class="bg-theme-color color-black save-btn font-bold"
            />
          </div>
          <!-- Updated section for non-verified domains and undeployed models using a table -->
          <div v-if="nonVerifiedDomains.length > 0" class="non-verified-domains mt-4">
            <h3 class="font-bold">Non-Verified Domains</h3>
            <table class="table-fixed w-full">
              <thead>
                <tr>
                  <th class="border px-4 py-2">Domain</th>
                  <th class="border px-4 py-2">Model Name</th>
                  <th class="border px-4 py-2">Verification Status</th>
                  <th class="border px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(domain, index) in nonVerifiedDomains" :key="index">
                  <td class="border px-4 py-2">{{ domain.domain }}</td>
                  <td class="border px-4 py-2">{{ domain.model_name }}</td>
                  <td class="border px-4 py-2">{{ domain.verification_status }}</td>
                  <td class="border px-4 py-2">
                    <Button
                      label="Verify"
                      @click="verifyDomain(domain)"
                      class="p-button-sm p-button-primary"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="undeployedModels.length > 0" class="undeployed-models mt-4">
            <h3 class="font-bold">Undeployed Models</h3>
            <table class="table-fixed w-full">
              <thead>
                <tr>
                  <th class="border px-4 py-2">Domain</th>

                  <th class="border px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(domain, index) in undeployedModels" :key="'model-' + index">
                  <td class="border px-4 py-2">{{ domain.domain }}</td>
                  <td class="border px-4 py-2">{{ domain.model_name }}</td>
                  <td class="border px-4 py-2">{{ domain.verification_status }}</td>
                  <td class="border px-4 py-2">
                    <Button
                      label="Deploy"
                      @click="deployModel(model)"
                      class="p-button-sm p-button-primary"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalAddDomainVue v-if="showModalDomain" />
  <ModalVerifyOwnership v-if="showModalVerifyOwnership" />
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import Button from 'primevue/button';
import ModalAddDomainVue from '../components/training/ModalAddDomain.vue';
import ModalVerifyOwnership from '../components/training/ModalVerifyOwnership.vue';

const testField = ref('');
const result = ref('');
const isLoading = ref(false);
const isInvalid = ref(false);
const showModalDomain = computed(() => store.state.showModalDomain);
const showModalVerifyOwnership = computed(() => store.state.showModalVerifyOwnership);
const nonVerifiedDomains = ref([]);
const undeployedModels = ref([]); // New reference for undeployed models

const store = useStore();

// Retrieve fineTunedModelId from Vuex state
const fineTunedModelId = localStorage.getItem('fineTunedModelId');

const testFunction = async () => {
  if (testField.value.trim() === '') {
    isInvalid.value = true;
    return;
  } else {
    isInvalid.value = false;
    isLoading.value = true;
  }

  const selectedModelId = localStorage.getItem('selectedModelId');
  const baseUrl = 'https://sh.islandaiforge.us';
  let url = '';

  let requestBody = {
    prompt: testField.value,
    model_type: selectedModelId,
  };

  try {
    if (selectedModelId === '5fc5c932-add4-40dc-b483-e9e22a2d72ac') {
      // Textual GPT model handling
      url = `${baseUrl}/generate`;

      // Add fineTunedModelId to the requestBody if available
      if (fineTunedModelId) {
        requestBody.fine_tuned_model_id = fineTunedModelId;
      }
    } else if (selectedModelId === 'another_model_id') {
      // Handle another model
      url = `${baseUrl}/another_model_endpoint`;
    } else if (selectedModelId === 'yet_another_model_id') {
      // Handle yet another model
      url = `${baseUrl}/yet_another_model_endpoint`;
    } else {
      result.value = 'Invalid model selection';
      isLoading.value = false;
      return;
    }

    const response = await axios.post(url, requestBody);
    result.value = response.data.text; // Assuming response structure for text

    isLoading.value = false;
  } catch (error) {
    console.error('Error:', error);

    if (error.message === 'Network Error') {
      result.value = 'Network Error: Please try again later.';
    } else {
      result.value = 'Error testing model';
    }

    isLoading.value = false;
  }
};

const deploy = () => {
  store.commit('showModalDomain', true);
  console.log('state deploy', store.state.showModalDomain);
};

const verifyDomain = (domain) => {
  // Ensure domain is properly logged to debug
  console.log('Verifying domain:', domain);

  localStorage.setItem('selectedModelId', domain.model_id);

  // Example: Dispatch an action to save domain name in Vuex
  store.dispatch('saveDomainName', domain.domain); // Assuming domain.domain contains the domain URL
  store.commit('showModalVerifyOwnership', true); // Example: Show a modal for ownership verification
};

const fetchNonVerifiedDomains = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/unverified-domains`);
    console.log('data',response.data)
    nonVerifiedDomains.value = response.data.unverified_domains; // Update with non-verified domains
    undeployedModels.value = response.data.undeployed_models; // Set undeployed models
    console.log('Fetched non-verified domains:', nonVerifiedDomains.value);
    console.log('Fetched undeployed models:', undeployedModels.value);
  } catch (error) {
    console.error('Error fetching non-verified domains:', error);
  }
};

onMounted(fetchNonVerifiedDomains);

const isPlanIdDeactivated = ref(false); // Example condition for enabling/disabling the "Deploy" button
</script>

<style scoped>
.main-content-container {
  width: 75%;
}

.main-left-column {
  border-radius: 10px;
  overflow: hidden;
  margin-right: 40px;
  color: #3e4450;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  margin-left: 2%;
}

.input-group {
  position: relative;
  width: 100%;
  margin-top: 2%;
}

.input-text {
  width: 100%;
  padding: 15px 10px 15px 10px; /* Add padding to make room for the button */
  border: 1px solid #ccc;
  border-radius: 9999px; /* Fully rounded */
  box-sizing: border-box; /* Ensure padding is included in the total width */
  font-size: 15px;
}

.search-button {
  position: absolute;
  right: 10px; /* Position the button inside the input */
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: #4ebf66;
  color: black;
  padding: 5px 25px;
  cursor: pointer;
  height: calc(100% - 12px); /* Adjust height to fit inside input */
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-button:hover {
  background-color: #a7e6b4;
}

.border-red {
  border: 2px solid red;
}

.columns-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Ensures alignment to the left */
  width: 100%;
}

.columns-container-spinner {
  display: flex;
  justify-content: center;
  align-items: center; /* Ensures alignment to the left */
  width: 100%;
}

.container-spinner {
  --d: 50px;
  width: 20px;
  margin-top: 10%;
  height: 20px;
  border-radius: 50%;
  color: #25b09b;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0, calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 2px, calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 3px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px, calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 5px,
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 6px;
  animation: l27 1s infinite steps(8);
}

@keyframes l27 {
  100% {
    transform: rotate(1turn);
  }
}

/* Add styles for the deactivated button */
.deactivated-button {
  background-color: grey;
  cursor: not-allowed;
  color: white;
}

.non-verified-domains,
.undeployed-models {
  margin-top: 20px;
}

/* Table styles */
.table-fixed {
  width: 100%;
  border-collapse: collapse;
}

.table-fixed th,
.table-fixed td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.table-fixed th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.p-button {
  margin-left: 10px;
}
</style>
