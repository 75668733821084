import { createRouter, createWebHistory } from "vue-router";
import DashboardPage from "../pages/DashboardPage.vue";
import ProfilPage from "../pages/ProfilPage.vue";
import TestingPage from "../pages/TestingPage.vue";
import ModelLibraryPage from "../pages/ModelLibraryPage.vue";
import VmManagementPage from "../pages/VmManagementPage.vue";
import LoginComponent from "../components/LoginComponent.vue";
import SignupComponent from "../components/SignupComponent.vue";
import EndpointPage from '../pages/EndpointPage.vue';
import WorkflowPage from "../pages/WorkflowPage.vue";
import FineTuning from "../pages/FineTuning.vue";
import ThankYou from "../pages/ThankYou.vue";


import { retrieveToken } from "../services/tokenService"; // Assuming tokenService.js is in the 'services' directory
import DataManagementPage from "@/pages/DataManagementPage.vue";

// Function to check if the user is authenticated
function isAuthenticated() {
  // Check if token exists in localStorage or any other authentication method you're using
  return retrieveToken() !== null;
}

const routes = [
  {
    path: "/",
    component: () => import("../layout/MainLayout.vue"),
    children: [
      {
        path: "/",
        name: "redirect",
        redirect: "/dashboard",
      },
      {
        path: "/logout",
        name: "logout",
        redirect: "/login",
      },
      {
        path: "/dashboard",
        name: "DashboardPage",
        component: DashboardPage,
      
      },
      {
        path: "/thank-you",
        name: "ThankYou",
        component: ThankYou,
      },
      {
        path: "/thank-you",
        name: "Tankyou",
        component: ThankYou,
      
      },
      {
        path: "/vm-management",
        name: "VmManagementPage",
        component: VmManagementPage,
        beforeEnter: (to, from, next) => {
          if (isAuthenticated()) {
            next();
          } else {
            next("/login");
          }
        },
      },
      {
        path: "/data-management",
        name: "DataManagementPage",
        component: DataManagementPage,
        beforeEnter: (to, from, next) => {
          if (isAuthenticated()) {
            next();
          } else {
            next("/login");
          }
        },
      },
      {
        path: "/profil",
        name: "ProfilPage",
        component: ProfilPage,
        beforeEnter: (to, from, next) => {
          if (isAuthenticated()) {
            next();
          } else {
            next("/login");
          }
        },
      },
      {
        path: "/workflows",
        name: "WorkflowPage",
        component: WorkflowPage,
        beforeEnter: (to, from, next) => {
          if (isAuthenticated()) {
            next();
          } else {
            next("/login");
          }
        }
      },
      {
        path: "/model-library",
        name: "ModelLibraryPage",
        component: ModelLibraryPage,
        beforeEnter: (to, from, next) => {
          if (isAuthenticated()) {
            next();
          } else {
            next("/login");
          }
        },
      },
      {
        path: "/test-endpoint",
        name: "TestingPage",
        component: TestingPage,
        beforeEnter: (to, from, next) => {
          if (isAuthenticated()) {
            next();
          } else {
            next("/login");
          }
        },
      },
      {
        path: "/endpoint",
        name: "EndpointPage",
        component: EndpointPage,
        beforeEnter: (to, from, next) => {
          if (isAuthenticated()) {
            next();
          } else {
            next("/login");
          }
        },
      },
      {
        path: "/fine-tuning",
        name: "FineTuning",
        component: FineTuning,
        beforeEnter: (to, from, next) => {
          if (isAuthenticated()) {
            next();
          } else {
            next("/login");
          }
        },
      },
    ],
  },
  {
    path: "/login",
    name: "LoginComponent",
    component: LoginComponent,
  },
  {
    path: "/signup",
    name: "SignupComponent",
    component: SignupComponent,
  },


  // other routes...
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
