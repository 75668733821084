<template>
    <div>
        <custom-modal v-if="showModal">
            <template #header>
                <div class="modal-header-content ml-4 mb-4">
                    <h3 class="header-title no-wrap">{{ modalTitle }}</h3>
                    <button class="close-button" @click="closeModal">
                        <i class="pi pi-times close-icon"></i>
                    </button>
                </div>
            </template>
            <template #body>
                <div class="ml-4 mr-4">
                    <div class="items-center">
                        <div class="justify-content-center items-center ml-5">
                            <div class="custom-checkbox" :class="{ 'checked': standardChecked }" @click="handleStandardCheck">
                                <input type="checkbox" v-model="standardChecked" class="p-checkbox" />
                                <label>Install model without training </label>
                            </div>
                        </div>
                    </div>
                    <div class="items-center">
                        <div class="justify-content-center items-center ml-5">
                            <div class="custom-checkbox" :class="{ 'checked': trainingChecked }" @click="handleTrainingCheck">
                                <input type="checkbox" v-model="trainingChecked" class="p-checkbox" />
                                <label>Train it with your data</label>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div v-if="loading" class="flex align-items-center justify-start mt-2 ml-4 mb-4 font-bold border-round m-2">
                    <ProgressSpinner class="spinner" />
                </div>
                <div v-else class="flex align-items-center justify-end mt-2 ml-4 mb-4 font-bold border-round m-2 mt-4">
                    <Button v-if="!loading" label="Previous" @click="previous" class="p-button-rounded mr-2 btn-cancel font-bold" />
                    <Button label="Next" @click="handleSubmit" :icon="loading ? '' : 'pi pi-arrow-right'" :disabled="loading" rounded class="bg-theme-color color-black save-btn font-bold" />
                </div>
            </template>
        </custom-modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';
import CustomModal from '../commons/modal/ModalComponent.vue';

export default {
    name: 'ModalTrainingInstallPreferenceStepOne',
    data() {
        return {
            modalTitle: "INSTALL PREFERENCES",
            standardChecked: true,
            trainingChecked: false,
            standardCheckedError: false,
            trainingCheckedError: false,
            loading: false
        };
    },
    components: {
        ProgressSpinner,
        Button,
        CustomModal,
    },
    computed: {
        showModal() {
            return this.$store.state.showModal;
        },
    },
    created() {
        this.loadFormData();
    },
    methods: {
        ...mapActions(['openModal', 'closeModal']),
        loadFormData() {
            const savedFormData = JSON.parse(localStorage.getItem('formData'));
            console.log('savedform dada',savedFormData)
            if (savedFormData && savedFormData.trainingInstallPreference) {
                console.log('savevff',savedFormData.trainingInstallPreference)
                this.standardChecked = savedFormData.trainingInstallPreference.standardChecked || false;
                this.trainingChecked = savedFormData.trainingInstallPreference.trainingChecked || false;
            }
        },
        handleStandardCheck() {
            this.standardChecked = !this.standardChecked;
            if (this.standardChecked) {
                this.trainingChecked = false;
            }
        },
        handleTrainingCheck() {
            this.trainingChecked = !this.trainingChecked;
            if (this.trainingChecked) {
                this.standardChecked = false;
            }
        },
        closeModal() {
            this.closeModal();
        },
        previous() {
            this.$emit('previous');
        },
        handleSubmit() {
            this.loading = true;

            if (!this.standardChecked && !this.trainingChecked) {
                this.standardCheckedError = true;
                this.trainingCheckedError = true;
                this.loading = false;
                return;
            } else {
                this.standardCheckedError = false;
                this.trainingCheckedError = false;
            }

            const formData = {
                standardChecked: this.standardChecked,
                trainingChecked: this.trainingChecked
            };
            this.$store.dispatch('saveFormData', { trainingInstallPreference: formData });
            this.$emit('next');
        }
    },
};
</script>

<style scoped>
.modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header-title {
    font-size: 24px; 
    font-weight: 700; 
    line-height: 36px;
    font-family: 'Poppins', sans-serif;
    vertical-align: baseline;
    margin: 0; 
    width: 333px;
    height: 17px;
    top: 396px;
    left: 645px;
}

.close-button {
    border: 2px solid red;
    background-color: red;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -38px;
    left: 100%;
    right: -12px;
    cursor: pointer;
    padding: 0;
}

@media (max-width: 400px) {
    .close-button {
        border: 2px solid red;
        background-color: red;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -38px;
        left: 100%;
        right: -12px;
        cursor: pointer;
        padding: 0;
    }
}

.close-icon {
    color: black;
    font-size: 20px;
}

.p-inputtext {
    border-radius: 9999px;
}

.spinner {
    width: 30px;
    height: 30px;
    justify-content: start;
    margin-left: 20px;
    --p-progress-spinner-color: green;
}

.no-wrap {
    white-space: nowrap;
}

.error {
    border: 1px solid red;
}

.error-message {
    color: red;
}

.btn-cancel {
    background-color: transparent;
    color: #14C871;
    border: 1px solid;
}

.custom-radio,
.custom-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: -40px;
    cursor: pointer;
    border-radius: 9999px;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #F0F0F0;
}

.custom-checkbox.checked {
    border-color: rgba(20, 200, 113, 0.1);
    background-color: rgba(20, 200, 113, 0.1);
}

.custom-checkbox .p-checkbox {
    margin-right: 5px;
    border-color: #14C871; 
}

.custom-checkbox label {
    cursor: pointer;
    color: black;
    margin-left: 10px;
}

.custom-checkbox input[type="checkbox"] {
    display: none;
}

.custom-checkbox label {
    cursor: pointer;
    color: black;
    margin-right: 5px;
}

.custom-checkbox input[type="checkbox"] + label::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.custom-checkbox input[type="checkbox"]:checked + label::before {
    background-color: #14C871;
    border-color: #14C871;
}
</style>
